.loaderContainer
  top: 0
  position: absolute
  background-color: $grayBlurBg
  height: 100%
  min-height: 100vh
  width: 100%
  z-index: 10000
  &__loader
    position: sticky
    top: 50vh
    left: calc(50% + 125px - 40px) //125 is the width of the sidebar, 40 is the width of the loader
    border: 5px solid #f4f3f3
    border-top: 5px solid #fdab0d
    border-radius: 50%
    width: 80px
    height: 80px
    animation: spin 1s ease-in-out infinite
    @media screen and (max-width: 1024px)
      left: calc(50% - 40px)
