.basicSelect
  +basicInputs
  appearance: none /* Masque la flèche native */
  -webkit-appearance: none /* Pour les navigateurs basés sur Webkit */
  -moz-appearance: none /* Pour Firefox */
  padding-right: 20px /* espace pour la flèche personnalisée */
  background: url('../../../../assets/images/icons/Picto/chevron-down.svg') no-repeat right 15px center /* Ajouter votre propre icône */
  background-size: 14px /* Taille de la flèche personnalisée */
  background-color: white
  &__option

.hoverable:hover
  cursor: pointer
