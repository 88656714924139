/* Import Wigrum Font */

@font-face {
  font-family: "Wigrum-light";
  src: url("../../public/assets/fonts/Wigrum/Wigrum-Light.otf");
}

@font-face {
  font-family: "Wigrum-Regular";
  src: url("../../public/assets/fonts/Wigrum/Wigrum-Regular.otf");
}

@font-face {
  font-family: "Wigrum-Medium";
  src: url("../../public/assets/fonts/Wigrum/Wigrum-Medium.otf");
}

@font-face {
  font-family: "Wigrum-Black";
  src: url("../../public/assets/fonts/Wigrum/Wigrum-Black.otf");
}

@font-face {
  font-family: "Wigrum-Bold";
  src: url("../../public/assets/fonts/Wigrum/Wigrum-Bold.otf");
}

:root {
  --main-color-blue: #1f3449;
  --main-color-blue-lighter: #135393c5;
  --main-color-blue-lighter: #6085aac5;
  /* --main-color-blue-lighter: #34597ec5; */
  --main-color-orange: #fdab0d;
  --main-color-orange-light: #ffd78e;
  --main-color-red: #ff2a2a;
  --main-color-grey: #f4f3f3;
  --main-color-dark-gray: #c4c4c4;
  --main-border-radius: 5px;
}

/* files uploads */
.dropZoneFileContainer {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  height: 10rem
}

@media screen and (max-width: 1024px) {
  .dropZoneFileContainer {
    flex-direction: row;
  }

}

.dropzoneForm {
  display: none;

}

.dropzone {
  border: 2px dashed var(--main-color-orange);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin-bottom: 1rem;
  width: 48%;

  .fileLabel {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .fileLabelSpan {
      color: var(--main-color-blue-lighter);
    }
  }
}

.dropzone:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  .fileLabel:hover {
    cursor: pointer;
  }
}

.image-error {
  color: var(--main-color-red);
}

.fileZone {
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .showFileName {
    .fileNameIcon {
      height: 2rem;

      >path {
        fill: var(--main-color-orange);
      }
    }

    .fileName {
      margin: 1rem 0;
      color: var(--main-color-blue-lighter);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .showSelectedImage {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    object-fit: contain;
    display: block;
    margin: auto;
  }
}

@media screen and (min-width: 1024px) {
  .dropZoneFileContainer {
    flex-direction: row;
  }

  .dropzone {
    margin-bottom: 0;
  }
}

.file-input-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

/* end files uploads */
.iconRotate {
  transform: rotate(135deg);
}

.unShow {
  display: none !important;
}

.inconColorGreen>path {
  fill: #26cb00;
}

.inconColorOrange>path {
  fill: #f6a817;
}

.inconColorRed>path {
  fill: var(--main-color-red);
}

.marginRight {
  margin-right: 0.5rem;
}

.marginRL10 {
  margin-right: 10px;
  margin-left: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Wigrum-Regular";
  color: var(--main-color-blue);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* POPUP */

.popUp {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--main-color-grey);
  padding: 1rem;
  border-radius: var(--main-border-radius);
  width: 80%;
  box-shadow: 0px 3px 9px 5px rgba(0, 0, 0, 0.34);
}

.closeIcon:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;
}

.interlocutorSettingsPop {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .titleContent {
    display: flex;
    justify-content: space-between;

    .title {
      margin-bottom: 1rem;
    }

    .closeIcon {
      cursor: pointer;
      height: 2rem;
      margin-left: 1rem;

      path {
        fill: var(--main-color-orange);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;

    .select {
      border: none;
      border-radius: 5px;
      width: fit-content;
    }

    .input {
      border: none;
      border-radius: var(--main-border-radius);
      height: 35px;
      padding: 0px 5px;
      margin: 0.5rem 0;
    }
  }

  .submitButton {
    cursor: pointer;
    margin-top: 1rem;
    background-color: var(--main-color-orange);
    border: none;
    color: white;
    padding: 5px;
    border-radius: 5px;
  }
}

@media screen and (min-width: 600px) {
  .popUp {
    width: fit-content;
  }
}

@media screen and (min-width: 1024px) {
  .popUp {
    transform: translate(0%, -50%);
  }
}

/* end POPUP */

.blur {
  filter: blur(4px);
  pointer-events: none;
}

.bluredDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.bluredDiv:hover {
  cursor: pointer;
}

.desableScroll {
  overflow: hidden;
}

.hidden {
  display: none;
}

.hidden-scroll {
  overflow: hidden;
}

.navigation-sidebar {
  display: inline-block;
  position: fixed;
  height: 100vh;
  right: 0;
  top: 0;
  width: 70%;
  background-color: var(--main-color-blue);
  z-index: 100;
}

.navigation-sidebar>.back-to-home-desktop {
  display: none;
}

.navigation-sidebar>.back-to-home {
  display: flex;
  align-items: center;
  position: relative;
  height: 85px;
  background-color: var(--main-color-orange);
  cursor: pointer;
}

.navigation-sidebar>.back-to-home p {
  color: white;
  font-family: "Wigrum-Medium";
  margin-left: 20px;
  font-size: 18px;
}

.navigation-sidebar>.back-to-home svg {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
}

.navigation-sidebar>.back-to-home svg path {
  fill: white;
}

.navigation-sidebar>.logout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 12%;
  border-bottom: 1px solid #dddddd;
}

.navigation-sidebar>.logout p {
  color: white;
  margin-left: 20px;
}

.navigation-sidebar>.logout p:last-child {
  color: var(--main-color-orange);
}

.navigation-sidebar>.menu {
  height: 52%;
}

.navigation-sidebar>.menu>nav {
  height: 100%;
}

.navigation-sidebar>.menu>nav>ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  list-style: none;
}

.navigation-sidebar>.menu>nav>ul>li {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.navigation-sidebar>.menu>nav>ul>li a {
  text-decoration: none;
  color: #dddddd;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 14px;
  position: relative;
}

.navigation-sidebar>.menu>nav>ul>li a.active {
  font-family: "Wigrum-Bold";
  color: white;
}

.navigation-sidebar>.menu>nav>ul>li a.activeTab {
  font-family: "Wigrum-Bold";
  color: white;
}

.navigation-sidebar>.menu>nav>ul>li a.active span {
  position: absolute;
  height: 100%;
  width: 5px;
  background-color: #e68600;
  left: 0;
  z-index: 10;
}

.reloadFilter {
  margin: 10px;
  height: 1.5rem;
  margin-right: 0 !important;
  cursor: pointer;
}

.xmark {
  position: absolute;
  right: 10px;
}

.navigation-sidebar>.menu>nav>ul>li a.activeTab span {
  position: absolute;
  height: 100%;
  width: 5px;
  background-color: #e68600;
  left: 0;
  z-index: 10;
}

.navigation-sidebar>.menu>nav>ul>li a.active:hover span {
  background-color: #e68600;
}

.navigation-sidebar>.menu>nav>ul>li a.activeTab:hover span {
  background-color: #e68600;
}

.navigation-sidebar>.menu>nav>ul>li a:hover span {
  position: absolute;
  height: 100%;
  width: 5px;
  background-color: white;
  left: 0;
  z-index: 10;
}

.navigation-sidebar>.menu>nav>ul>li a:hover {
  font-family: "Wigrum-Bold";
  color: white;
}

/* SETTINGS PAGE */

.settingsContainer {
  /* border: red 1px solid; */
  width: 90%;
  margin-left: 5%;
}

.settingsHeader {
  align-items: center;
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid var(--main-color-orange);
  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-content: space-between;
}

.setHeaderLeftGroup {
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.setHeaderLeftGroup>svg {
  margin-right: 1rem;
}

.setHeaderLeftGroup>p {
  text-transform: uppercase;
}

.setHeaderRightGroup {
  display: flex;
  flex-direction: column;
}

.setHeaderContent {
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

.settingsUserGuideContent {
  border: 1px solid var(--main-color-orange-light);
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}

.settingsUserGuideContent:hover {
  cursor: pointer;
}

.settingsUserGuideContent>p {
  margin-right: 5px;
  color: var(--main-color-orange);
}

.settingsUserGuideContent>svg>path {
  color: var(--main-color-orange);
}

.settingsSave {
  background-color: var(--main-color-grey);
  border-radius: var(--main-border-radius);
  border: none;
  padding: 10px;
  display: flex;
  align-items: center;
  width: fit-content;

  .icon {
    height: 1.3rem;
    margin-right: 10px;
  }
}

.settingsSave:hover {
  cursor: pointer;
}

.settingsSave>p {
  margin-left: 5px;
  font-family: "wigrum-medium";
}

.settingsLicence>p {
  margin-left: 5px;
  font-size: 0.8rem;
  font-family: "wigrum-medium";
}

.settingsLicence:hover {
  cursor: pointer;
  text-decoration: underline;
}

.settignsWarning {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.settignsWarning>p>span {
  text-decoration: underline;
  font-family: "wigrum-bold";
}

.setInputContainer {
  background-color: var(--main-color-grey);
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 3rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.textAreaGroup {
  flex-direction: column !important;
}

.textAreaTilteContent {
  display: flex;

  svg {
    margin-left: 1rem;
  }
}

/* .setLeftInpGroup {
  width: 45%;
} */
/* .setRightInpGroup {
  width: 45%;
} */

.setInpGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;

  label {
    align-content: center;
  }
}

.setInpGroupLine4 label {
  align-content: center;
}

.setInpGroup>input {
  border: none;
  border-radius: var(--main-border-radius);
  height: 35px;
  padding: 0px 5px;
}

.setInpGroupLine3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.setInpGroupLine3>div {
  /* width: 60%; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  :first-child {
    margin-bottom: 5px;
  }
}

.setInpGroupLine3>div>input {
  border: none;
  border-radius: var(--main-border-radius);
  height: 35px;
  padding: 0px 5px;
}

.setInpGroupLine3>div>input {
  border: none;
  border-radius: var(--main-border-radius);
  height: 35px;
  padding: 0px 5px;
}

/* .setInpGroupLine3 > div > input:first-child {
  width: 25%;
} */

/* .setInpGroupLine3 > div > input:nth-child(2) {
  width: 70%;
} */

.setInpGroupLine4 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: column;
}

.setInpGroupLine4>div {
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
}

/* .setInpGroupLine4 > div > input:first-child {
  width: 25%;
} */
.textAreaIcon {
  display: none;
}

.setInpGroupLine4>div>input {
  border: none;
  border-radius: var(--main-border-radius);
  height: 35px;
  padding: 0px 5px;
}

@media screen and (min-width: 1024px) {
  .setInpGroupLine4>div>input {
    width: 5rem;
  }

}

.setTextGroup {
  display: flex;
  flex-direction: column;
}

.setTextGroup>p {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.setTextGroup>p>span {
  font-family: "wigrum-bold";
}

@media screen and (max-width: 1024px) {

  .setLeftInpGroup {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
  }

  .setLeftInpGroup>div {
    width: calc(50% - 8px);
    gap: 10px;
  }

  .siretDiv {
    gap: 10px;
  }

  .setRightInpGroup {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
  }

  .setRightInpGroup>div {
    width: calc(50% - 8px);
  }

  .setRightInpGroup>.dropZoneFileContainer {
    width: 100%;
  }

  .setInpGroupLine3 {
    justify-content: space-around;
  }

}

@media screen and (max-width: 600px) {
  .setLeftInpGroup {
    flex-direction: column;
  }

  .setRightInpGroup {
    flex-direction: column;
  }

  .setLeftInpGroup>div {
    width: 100%;
  }

  .setRightInpGroup>div {
    width: 100%;
  }
}

@media screen and (min-width: 1025px) {
  .setHeaderLeftGroup {
    margin: 0;
  }

  .settingsHeader {
    flex-direction: row;
  }

  .setHeaderRightGroup {
    display: flex;
    flex-direction: row;
  }

  .settingsUserGuideContent {
    margin: 0 1rem;
  }

  .setInputContainer {
    flex-direction: row;
  }

  .setLeftInpGroup {
    width: 50%;
    align-content: center;
  }

  .setInpGroupLine4 {
    margin-bottom: 10px;
    flex-direction: column;
    gap: 10px;

    .siretInput {
      width: 25%;
    }

    .siretDiv {
      min-width: 60%;
    }
  }

  .setInpGroup>input {
    width: 60%;
  }

  .setInpGroupLine3 {
    margin-bottom: 10px;
    flex-direction: row;
  }

  .setInpGroupLine3>div {
    flex-direction: row;
    width: 60%;
  }

  .setInpGroupLine3>div>input:first-child {
    width: 25%;
  }

  .setInpGroupLine3>div>input:nth-child(2) {
    width: 70%;
  }

  .setTextGroup {
    align-items: flex-end;
  }

  .setInpGroup {
    flex-direction: row;
    margin-bottom: 10px;
  }

  .setInpGroupLine4>div {
    flex-direction: row;
    min-width: 60%;
    align-items: center;
  }

  .setRightInpGroup {
    width: 45%;
  }

  .setInpGroupLine4>div>label {
    margin-left: 10px;
    margin-right: 10px;
  }

  .setTextGroup>p {
    width: 60%;
  }

  .settingsMarginDiv {
    width: 60%;
  }

  .setInpGroupLine4>div>input:first-child {
    width: 5rem;
  }

  .textAreaIcon {
    display: block;
  }

  .settingsTextarea {
    width: 60% !important;
  }
}

@media screen and (min-width: 1440px) {
  .setInpGroupLine4 {
    flex-direction: row;
  }
}

/* END SETTINGS PAGE */

.payementDate {
  margin-top: 1rem;
}

.payementDate :nth-child(2) {
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.payementDate :nth-child(2)>p {
  margin-left: 5px;
}

/* .payementDate > p{
  margin-top: 1rem;
  margin-bottom: 10px;
} */
.payementDate>p>svg {
  margin-right: 5px;
}

/* interlocuteur */

.interlocuteur {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.settingsDeletIcon {
  cursor: pointer;
  height: 2rem;
  background-color: var(--main-color-red);
  padding: 10px;
  border-radius: 10px;
}

.settingsDeletIcon>path {
  color: white;
}

.setInterlocuteurBtn {
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;

  p {
    display: none;
    color: white;
  }

  svg {
    background-color: var(--main-color-orange);
    padding: 10px;
    border-radius: 10px;
    height: 1.5rem;

    path {
      color: white;
    }
  }
}

.setInterlocuteurBtn>svg {
  margin-right: 10px;
}

/* end interlocuteur */

/* Accountant */

.accountantGroup {
  cursor: pointer;
  margin: 1rem 0;
  background-color: var(--main-color-grey);
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  height: 45px;

  .accountantContent {
    width: fit-content;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    p {
      display: flex;
      margin-right: 1rem;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: var(--main-border-radius);
      height: 35px;
      padding: 10px;
    }

    label {
      display: block;
      margin-right: 1rem;
    }
  }

  .accountantDelete {
    display: none;
    margin-left: 2rem;
  }
}

.accountantGroup:hover {
  box-shadow: 0px 0px 10px 0px #00000048;
  border-radius: 10px;
}

.accountantMail {
  display: flex;

  p {
    display: flex;
    margin-right: 1rem;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: var(--main-border-radius);
    height: 35px;
    padding: 10px;
  }

  label {
    margin-right: 1rem;
  }
}

.accountantInput {
  margin-left: 1rem;
  border: none;
  border-radius: var(--main-border-radius);
  height: 35px;
  padding: 0px 5px;
}

@media screen and (min-width: 1024px) {
  .accountantGroup {
    margin: 2rem 0;
    flex-direction: row;
    align-items: center;

    .accountantContent {
      margin-bottom: 0;

      p {
        display: flex;
        margin-right: 1rem;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: var(--main-border-radius);
        height: 35px;
        padding: 10px;
      }

      label {
        display: block;
        margin-right: 1rem;
      }
    }

    .accountantDelete {
      display: block;
    }
  }

  .accountantMail {
    align-items: center;

    label {
      display: block;
    }
  }
}

/* end accountant */

.settingsTextarea {
  height: auto !important;
  width: 100% !important;
  margin-top: 5px;
}

.settingsMarginDiv {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.settingsMarginDiv>svg>path {
  color: var(--main-color-orange);
}

.settingsMarginDiv>input {
  width: 92%;
  border: none;
  border-radius: var(--main-border-radius);
  height: 35px;
  padding: 0px 5px;
}

.setFileGroup {
  display: flex;
  justify-content: space-between;
}

.setFileGroup>div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.setFileGroup>div>input {
  display: none;
}

.setFileGroup>div>label {
  margin-top: 1rem;
  background-color: var(--main-color-orange);
  color: white;
  width: fit-content;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.setFileGroup>div>label.labelActive {
  background-color: transparent;
  color: var(--main-color-blue-lighter);
  font-size: 0.8rem;
}

/* .navigation-sidebar>.settings {
    height: 16%;
    display: flex;
    flex-direction: column;
}

.navigation-sidebar>.settings p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50%;
    color: white;
    border: 1px solid white;
    border-radius: 4px;
    margin: 10px;
    position: relative;
}

.navigation-sidebar>.settings>p a {
    height: 100%;
    width: 100%;
    padding-left: 10px;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
}

.navigation-sidebar>.settings>p>a>i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
} */

.navigation-sidebar>.settings {
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navigation-sidebar>.settings a {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20%;
  color: #dddddd;
  border: 1px solid #ddddddaf;
  border-radius: 4px;
  margin: 10px;
  position: relative;
  padding: 20px 0px 20px 10px;
  position: relative;
  transition: 0.3s;
  font-size: 14px;
}

.navigation-sidebar>.settings a:hover {
  border: 1px solid white;
  color: white;
}

.navigation-sidebar>.settings a:last-child {
  color: var(--main-color-orange);
  border: 1px solid #ffa5278d;
}

.navigation-sidebar>.settings a:last-child:hover {
  border: 1px solid var(--main-color-orange);
  color: var(--main-color-orange);
}

.navigation-sidebar>.settings a:last-child svg path {
  fill: #ffa5278d;
  transition: 0.3s;
}

.navigation-sidebar>.settings a:last-child:hover svg path {
  fill: var(--main-color-orange);
}

.navigation-sidebar>.settings>a>svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: #dddddd;
  fill: #dddddd;
}

.navigation-sidebar>.settings>a>svg path {
  fill: #dddddd;
}

.navigation-sidebar>.promo {
  height: 16%;
  padding-left: 20px;
}

.navigation-sidebar>.promo p {
  color: #cbccd0;
  font-size: 12px;
  font-family: "Wigrum-Light";
  margin-bottom: 1rem;
}

.navigation-sidebar>.promo img {
  height: auto;
  width: 200px;
  margin-bottom: 1rem;
}

/* 
.alert {
    position: absolute;
    top: 50px;
    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
} */

.alert {
  position: absolute;
  right: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  animation: deplacement 1s ease-in-out forwards;
  width: max-content;
}

@keyframes deplacement {
  from {
    top: -50px;
  }

  to {
    top: 20px;
  }
}

/* Dashboard */

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightLabel {
  position: absolute;
  left: 10%;
  top: 5%;
}

@media screen and (max-width: 1024px) {
  .rightLabel {
    top: auto;
    bottom: 5%;
    left: 10%;
  }
}

.pieLabel {
  display: flex;
  font-family: "Wigrum-light";
  font-size: 14px;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: normal;

}

.labelColor {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  border-radius: 50%;
}

.labelColor1 {
  background-color: var(--main-color-orange);
}

.labelColor2 {
  background-color: var(--main-color-dark-gray);
}

.paper>div>svg {
  width: 100% !important;
}

.orangeLine {
  border: 1px solid var(--main-color-orange);
  margin: 1rem 0;
  /* width: 100%; */
}

.dashboardTitle {
  font-family: "Wigrum-light";
}

.titleSpan {
  font-family: "Wigrum-bold";
}

.filterSection {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem
}

.contentFilter {
  display: flex;
  /* font-family: "Wigrum-bold"; */
}

.contentFilter>div {
  display: flex;
  /* font-family: "Wigrum-bold"; */
}

.contentFilter>.txt {
  font-family: "Wigrum-medium";
  margin-right: 1rem;
}

.rate {
  background-color: var(--main-color-dark-gray);
  padding: 5px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.rateSelected {
  background-color: var(--main-color-orange);
}

.head span {
  font-family: "Wigrum-Bold";
}

.dashboardContainer {
  margin-inline: 6%;
  margin-top: 15px;
}

.dashboardContent {
  margin-top: 2rem;
}

.forecast {
  /* border: 1px solid red; */
  margin-top: 1rem;
  height: 20rem;
  border-radius: 1rem;
}

.graphGrid {
  margin-top: 1rem;
  /* border: 1px solid green; */
  display: grid;
  height: auto;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.graph {
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-navigation {
  height: 85px;
  background-color: var(--main-color-blue);
  width: 100%;
  position: fixed;
  z-index: 100;
}

.menuToggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--main-color-orange);
  border-radius: 8px;
  padding: 8px;
  width: 60px;
  height: 60px;
  color: white;
  cursor: pointer;
}

.menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

.menuToggle span {
  display: block;
  top: 4px;
  width: 33px;
  height: 4px;
  margin-bottom: 7px;
  position: relative;
  background: white;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

.menu-navigation img {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  width: 180px;
}

.spacer {
  width: 100%;
  height: 85px;
}

/* toolbar */
.toolbar {
  padding: 15px 0px;
  display: flex;
  align-items: center;
  width: 100%;
  top: 0;
  position: sticky;
  background-color: white;
  z-index: 10;
}

.toolbar>.title {
  width: 50%;
  padding-left: 30px;
}

.toolbarInput {
  font-size: 25px;
  font-family: "Wigrum-Light";
}

.toolbarInput>.input {
  font-size: 22px;
  border-radius: 4px;
  font-family: "Wigrum-Bold";
  width: 150px;
  padding: 5px;
}

.hr {
  border: none;
  background-color: var(--main-color-orange);
  border-radius: 10px;
  height: 2px;
}

.saveButton {
  background-color: var(--main-color-orange);
  border: none;
  border-radius: 10px;
  padding: 1.4rem 10px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
  margin: 0 1rem;
  cursor: pointer;
}

.saveImg {
  margin-left: 5px;
}

.createDepositcontent {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 5rem;
}

.leftPart {
  background-color: var(--main-color-grey);
  border-radius: 10px;
  padding: 1rem;
}

.titleDeposit {
  display: flex;
  flex-direction: column;
}

.titleDeposit input {
  border: 0;
  outline: 0;
  border-bottom: 1px solid var(--main-color-blue);
  color: var(--main-color-blue);
  width: 100%;
  margin: 10px 0px;
  font-size: 16px;
  background-color: transparent;
  margin: 10px 0px;
  width: min-content;
}

.depositInfosTxt {
  display: flex;
  justify-content: space-between;
}

.descDeposit {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.descDeposit textarea {
  border: none;
  width: 75%;
  outline: none;
  margin: 10px 0px;
  padding: 10px;
  height: 100px;
  color: var(--main-color-blue);
  border-radius: 6px;
  max-width: 100%;
}

.list>.toolbar {
  padding: 15px 0px;
  display: flex;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 85px;
  z-index: 5;
  background-color: white;
  z-index: 90;
}

.list>.toolbar>.title {
  width: 50%;
  padding-left: 30px;
}

.list>.toolbar>.title h2 {
  font-size: 25px;
  font-family: "Wigrum-Light";
}

.list>.toolbar>.button {
  width: 50%;
  float: right;
  padding-right: 30px;
}

.list>.toolbar>.button a {
  float: right;
  background-color: var(--main-color-orange);
  padding: 10px;
  border-radius: 6px;
  text-decoration: none;
  color: white;
}

.list>.toolbar>.button a svg path {
  fill: white;
}

.type {
  width: 100%;
  height: 50px;
  background-color: var(--main-color-grey);
  display: inline-flex;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.type::-webkit-scrollbar {
  display: none;
}

.type div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin: 0px 15px;
}

.type div:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  height: 1px;
  width: 70%;
  border-bottom: 3px solid #dddddd;
}

/* 
.stateFilter.active::before{  
  border-bottom: 3px solid var(--main-color-orange) !important;
} */

.type div.active:before {
  border-bottom: 3px solid var(--main-color-orange);
}

.type div h3 {
  width: 80px;
  text-align: center;
  font-size: 12px;
  font-family: "Wigrum-Medium";
  color: var(--main-color-blue);
}



.type div.active h3 {
  font-family: "Wigrum-Bold";
}

.filter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  margin: 0px 10px;
}

.filter>.filter-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 0px;
}

.filter>.filter-component div {
  display: flex;
  width: fit-content;
  margin: 5px 0px;
}

.statTxtGroupCard {
  margin: 5px 0;
  flex-direction: row !important;
  align-items: center !important;
}

.filter>.filter-component div p {
  font-family: "Wigrum-Medium";
  font-size: 14px;
  width: 100%;
}

.filter>.filter-component div select {
  font-family: "Wigrum-Medium";
  border: none;
  border-bottom: 1px solid black;
  font-size: 14px;
  width: 100%;
}

.filter>.filter-component div a {
  text-decoration: none;
  font-family: "Wigrum-Medium";
  font-size: 14px;
  padding: 6px;
  background-color: var(--main-color-orange);
  color: white;
  border-radius: 6px;
  margin: 0px 5px;
}

.cards-list {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cards-list>a {
  width: 85%;
  text-decoration: none;
}

.cards-list>.card>.tileCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  position: relative;
}

.typeCard {
  display: none;
}

.cardOptionsPopButton {
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  right: 25px;
  top: 20px;
  background-color: var(--main-color-grey);
  border-radius: 50%;
  cursor: pointer;
}

.cardOptionsPopButton:hover {
  background-color: var(--main-color-dark-gray);
  transform: scale(1.1);
  transition: transform 0.3s, background-color ease-in-out 0.3s;
}

.cardOptionsPop {
  position: absolute;
  padding: 1rem;
  z-index: 20;
  right: 5%;
  bottom: -25%;
  border-radius: var(--main-border-radius);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  .line {
    width: 95%;
    margin: 5px 0px;
  }

  .button {
    user-select: none;
    font-family: "Wigrum-light";
    letter-spacing: 0.5px;
    width: 100%;
    cursor: pointer;

    >svg {
      margin-right: 5px;
    }
  }

  .button:hover {
    text-decoration: underline;
  }

  .buttonGroup {
    width: 100%;
  }

  .firstLine {
    display: flex;
    align-items: center;

    .closeCardOption {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;
    }

    .closeCardOption>svg {
      height: 1.2rem;
    }

    .closeCardOption:hover {
      transform: scale(1.3);
    }
  }

  .buttonGroup :nth-child(2) {
    margin: 5px 0;
  }
}

.card {
  position: relative;
  width: 100%;
  border: 2px solid var(--main-color-grey);
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  /* justify-content: space-between; */
}

.firstLineCard {
  display: flex;
  flex-direction: row !important;
}

.card>.ellipsis>.ellipsis-button,
.card>.ellipsis>.ellipsis-xmark {
  position: absolute;
  margin-top: 1rem;
  right: 9vw;
  z-index: 10;
  cursor: pointer;
  background: #f4f3f3;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card>.ellipsis>svg path {
  fill: #797979;
}

.card>.ellipsis>.option {
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 20;
  right: 18vw;
  width: 250px auto;
  margin-top: 1rem;
  filter: drop-shadow(1px 2px 10px rgba(0, 0, 0, 0.25));
}

.card>.ellipsis>.option svg {
  margin-right: 0.5rem;
}

.card>.ellipsis>.option>.mark,
.card>.ellipsis>.option>.modify,
.card>.ellipsis>.option>.download,
.card>.ellipsis>.option>.duplicate,
.card>.ellipsis>.option>.delete {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 1rem;
  border-bottom: 0.75px solid #f4f3f3;
}

.card>.ellipsis>.option>.mark p,
.card>.ellipsis>.option>.modify p,
.card>.ellipsis>.option>.download p,
.card>.ellipsis>.option>.duplicate p,
.card>.ellipsis>.option>.delete p {
  width: 100%;
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
  display: flex;
  align-items: center;
}

.card>.ellipsis>.option>.mark p:hover,
.card>.ellipsis>.option>.modify p:hover,
.card>.ellipsis>.option>.download p:hover,
.card>.ellipsis>.option>.duplicate p:hover,
.card>.ellipsis>.option>.delete p:hover {
  background: #f4f3f3;
  border-radius: 5px;
  cursor: pointer;
}

.quoteLink {
  width: 100%;
  text-decoration: none;
}

.validated svg path {
  fill: #26cb00;
}

.paid svg path {
  fill: #26cb00;
}

.canceled svg path {
  fill: #f62417;
}

.signed svg path {
  fill: #f6a817;
}

.unpaid svg path {
  fill: #f6a817;
}

.draft svg path {
  fill: #666666;
}

.card>.ellipsis>.option>.delete>.trash-can svg {
  padding: 4px;
  background-color: var(--main-color-red);
  border-radius: 50%;
}

.card>.ellipsis>.option>.delete>.trash-can svg path {
  filter: brightness(1) invert(1);
}

.cards-list>.card>.tileCard {
  cursor: pointer;
  overflow: hidden;
}

.cards-list>.card>.tileCard div {
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.cards-list>.card>.tileCard>.date {
  flex-direction: row;
  align-items: center;
}

.cards-list>.card>.tileCard div p {
  padding-right: 0.5rem;
}

.cards-list>.card>.tileCard div.state p.state-text {
  margin-right: 10px;
  background-color: var(--main-color-blue);
  border-radius: 5px;
  color: white;
  padding: 5px;
  font-size: 15px;
}

.cards-list>.card>.tileCard div.state p.type-text {
  margin-right: 10px;
  border-radius: 5px;
  color: white;
  padding: 5px;
  font-size: 15px;
}

.profileVueContent {
  margin-top: 1rem;
}

.infoGroup {
  /* border: 1px solid red; */
  margin: 0.5rem 0 1rem 0;
}

.p {
  color: white;
  font-family: "Wigrum-light";
}

.profileVueSpan {
  font-style: normal;
  color: white;
  font-family: "Wigrum-Regular";
}

.cards-list>.card>.tileCard div.state p.type-text.customer {
  background-color: #49c263;
}

.cards-list>.card>.tileCard div.state p.type-text.provider {
  background-color: var(--main-color-orange);
}

.cards-list>.card>.tileCard div.state p.type-text.prospect {
  background-color: #d3d3d3;
}

/* 
.cards-list>.card>.tileCard div.state {
    text-decoration: none;
} */

.cards-list>.card>.tileCard div.state:hover {
  text-decoration: underline;
}

.cards-list>.card>.tileCard div.state p.ref {
  font-family: "Wigrum-Regular";
  color: var(--main-color-blue);
  margin-right: 10px;
  font-size: 20px;
  font-weight: 400;
}

.cards-list>.card>.tileCard div.state p.ref span {
  font-family: "Wigrum-Medium";
  font-size: 20px;
  font-weight: 700;
}

.cards-list>.card>.tileCard div.name p {
  font-family: "Wigrum-Medium";
  font-size: 14px;
  color: var(--main-color-blue);
}

.cards-list>.card>.tileCard div.address p {
  font-family: "Wigrum-Light";
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: var(--main-color-blue);
}

.cards-list>.card>.tileCard div.price svg {
  margin-right: 8px;
}

.cards-list>.card>.tileCard div.price svg path {
  fill: var(--main-color-orange);
}

.cards-list>.card>.tileCard div.price>.ht {
  color: var(--main-color-orange);
  font-family: "Wigrum-Medium";
  font-size: 18px;
  margin-right: 8px;
}

.cards-list>.card>.tileCard div.price>.percent {
  color: var(--main-color-orange);
  font-family: "Wigrum-Medium";
  font-size: 18px;
  margin-right: 8px;
}

.cards-list>.card>.tileCard div.price>.ttc {
  font-family: "Wigrum-Light";
  font-size: 14px;
}

.cards-list>.card>.tileCard div.date svg {
  margin-right: 8px;
}

.cards-list>.card>.tileCard div.date svg path {
  fill: var(--main-color-orange);
}

.hidden-filter {
  display: none;
}

.card-filter {
  width: 88%;
  height: 450px;
  background-color: var(--main-color-grey);
  position: absolute;
  z-index: 50;
  top: 17rem;
  left: 6%;
  border-radius: 5px;
}

.row-one,
.row-two {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 1rem;

  >input {
    width: auto;
    border-radius: 5px;
    padding: 5px;
    border: none;
    margin-right: 5px;
  }

  >button {
    padding: 5px !important;
    border-radius: var(--main-border-radius);
    border: none;
  }

  >button:hover {
    background-color: var(--main-color-orange-light) !important;
  }
}

.card-filter input {
  width: 100%;
  height: 35px;
  border: none;
  border-radius: 5px;
  margin-top: 1rem;
  padding-left: 1rem;
}

.card-filter select {
  width: 100%;
  height: 35px;
  border: none;
  border-radius: 5px;
  margin-top: 1rem;
  padding-left: 1rem;
}

#filter-submit {
  padding: 0;
  width: 100%;
  background-color: var(--main-color-orange);
  color: white;
  cursor: pointer;
  margin-top: 2rem;
}

.cross-rotate {
  animation: rotate-forward 0.2s linear forwards;
}

@keyframes rotate-forward {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(45deg);
  }
}

.cross-reverse {
  animation: rotate-reverse 0.2s linear forwards;
}

@keyframes rotate-reverse {
  from {
    transform: rotate(45deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.add-button {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--main-color-orange);
  border-radius: 50%;
  color: white;
  font-size: 34px;
}

.letterFilterGroup {
  display: flex;
  margin-left: 1rem;
  overflow: scroll;
  width: 80%;
  justify-content: space-around;
}

.letterFilter {
  margin: 0 !important;
  /* padding: 2px; */
  font-size: 1.5rem;
}

.add-button svg {
  /* padding: 10px; */
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  vertical-align: middle;
}

.add-button svg path {
  fill: white;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 20px;
}

.footer p {
  font-size: 12px;
}

/* The Modal (background) */
.modal {
  display: none;
  position: absolute;
  z-index: 3;
  width: 250px;
  overflow: auto;
  background-color: white;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.modal-content>div {
  padding: 10px;
  color: var(--main-color-blue);
  font-family: "Wigrum-Regular";
}

.modal-content div:first-child svg path {
  fill: var(--main-color-orange);
}

.modal-content div:last-child {
  color: red;
}

.modal-content>div svg {
  margin-right: 5px;
}

#modalCloser {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

#modalCloser:hover,
#modalCloser:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/****** DEVIS ********/

.contract {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.contract>.toolbar {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin: 0px -5px 10px -5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  z-index: 100;
  background-color: white;
}

.contract>.toolbar>.header {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.contract>.toolbar>.header>.ref {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contract>.toolbar>.header>.ref>.num {
  display: flex;
  align-items: center;
}

.contract>.toolbar>.back-icon {
  display: none;
}

.contract>.toolbar>.header>.ref h2 {
  display: none;
  font-family: "Wigrum-Light";
  font-size: 22px;
  margin-right: 10px;
}

.contract>.toolbar>.header>.ref input {
  width: 180px;
  font-size: 22px;
  border-radius: 4px;
  font-family: "Wigrum-Bold";
}

.contract>.toolbar>.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.contract>.toolbar>.tools>* {
  margin: 0px 6px;
}

.contract>.toolbar>.tools>a {
  text-decoration: none;
  padding: 8px;
  border-radius: 4px;
  color: white;
  margin: 0px 2px;
  /* display: flex;
  align-items: center; */
}

.contract>.toolbar>.tools>a>svg>path {
  fill: white;
}

.contract>.toolbar>.tools>button {
  all: unset;
  cursor: pointer;
  text-decoration: none;
  padding: 8px;
  border-radius: 4px;
  color: white;
  margin: 0px 2px;
}

.contract>.toolbar>.tools>a>p {
  display: none;
  color: white;
}

.contract>.toolbar>.tools>button>p {
  display: none;
  color: white;
}

.contract>.toolbar>.tools button.save {
  background-color: var(--main-color-orange);
}

/* .contract > .toolbar > .tools >.dropdown> .dropdown-toggle> svg {
  height: 1.5rem;
} */
.contract>.toolbar>.tools button.save>svg,
.contract>.toolbar>.tools>.dropdown>.dropdown-toggle>svg {
  height: 1.5rem;
}

.contract>.toolbar>.tools button.save>svg>path {
  fill: white;
}

.contract>.toolbar>.tools button.save:hover {
  background-color: #e68600;
}

.contract>.toolbar>.tools a.settings {
  background-color: var(--main-color-grey);
  color: var(--main-color-blue);
}

.quoteButton {
  background-color: var(--main-color-blue) !important;
}

.quoteButton:hover {
  background-color: #2c4967 !important;
}

.iconButton path {
  fill: white;
}

.iconButton {
  height: 25px;
  margin-left: 5px;
}

.saveButton path {
  fill: white;
}

.saveButton {
  height: 25px;
}

.contract>.toolbar>.tools a.garbage {
  background-color: red;
  color: white;
  padding: 8px 8px;
}

.contract>.toolbar>.tools a.garbage svg {
  height: 25px;
}

.contract>.toolbar>.tools a.garbage svg path {
  fill: white;
}

.contract>.toolbar>.tools a.garbage:hover {
  background-color: rgb(208, 20, 20);
}

.contract>.toolbar>.tools>.dropdown {
  position: relative;
}

.contract>.toolbar>.tools>.dropdown>a {
  text-decoration: none;
  padding: 12px 8px;
  border-radius: 8px;
  margin: 0px 2px;
  background-color: var(--main-color-grey);
  color: var(--main-color-blue);
  display: inline-flex;
}

.contract>.toolbar>.tools>.dropdown a.dropdown-toggle p {
  display: none;
}

.contract>.toolbar>.tools>.dropdown>.dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  padding: 10px;
  right: 0;
}

.contract>.toolbar>.tools>.dropdown:hover .dropdown-menu {
  display: block;
}

.contract>.toolbar>.tools>.dropdown>.dropdown-menu a {
  text-decoration: none;
  border-style: none;
  color: var(--main-color-blue);
  padding: 6px 4px;
  z-index: 2;
  font-size: 16px;
  display: inline-flex;
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.contract>.toolbar>.tools>.dropdown>.dropdown-menu a p {
  margin-left: 5px;
}

.contract>.toolbar>.tools>.dropdown>.dropdown-menu a:hover p {
  text-decoration: none;
  border-style: none;
  font-family: "Wigrum-Bold";
}

.contract>.toolbar hr {
  position: absolute;
  bottom: 0;
  border-style: none;
  border-top: 3px solid var(--main-color-orange);
  width: 100%;
}

.contract>.toolbar>.header>.ref>.date {
  display: flex;
}

.contract>.toolbar>.header>.ref>.date {
  display: flex;
  width: 100%;
  align-items: center;
}

.contract>.toolbar>.header>.ref>.date {
  display: inline-flex;
  margin-top: 1rem;
}

.contract>.toolbar>.header>.ref>.date a {
  background-color: var(--main-color-grey);
  padding: 8px 10px;
  border-radius: 10px;
  text-decoration: none;
  color: var(--main-color-blue);
  font-size: 14px;
  font-family: "Wigrum-Medium";
  transition: 0.3s;
  margin-right: 8px;
}

.contract>.toolbar>.header>.ref>.date a:hover {
  background-color: var(--main-color-blue);
  color: white;
}

.contract>.toolbar>.header>.ref>.date a:hover svg {
  filter: brightness(1) invert(1);
}

.datePayment {
  display: flex;
}

.datePayment {
  display: flex;
  width: 100%;
  align-items: center;
}

.datePayment {
  display: inline-flex;
}

.datePayment h4 {
  margin-right: 10px !important;
}

.datePayment a {
  background-color: var(--main-color-grey);
  padding: 8px 10px;
  border-radius: 10px;
  text-decoration: none;
  color: var(--main-color-blue);
  font-size: 14px;
  font-family: "Wigrum-Medium";
  transition: 0.3s;
  margin-right: 8px;
}

.datePayment a:hover {
  background-color: var(--main-color-blue);
  color: white;
}

.datePayment div {
  top: auto;
  bottom: 40px;
}

/* PROSPECT CSS */

.titleLine {
  width: 60%;
  margin-bottom: 2rem;
  width: fit-content;
  /* cursor: pointer; */
}

.seeMore {
  display: flex;
  justify-content: center;
}

.seeMoreButton {
  cursor: pointer;
}

.seeMoreButton:hover {
  text-decoration: underline;
}

.create-prospect {
  margin-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Nothing {
  color: rgb(151, 141, 141);
  font-style: italic;
  font-family: "baloo 2";
}

/* .thForm {

    font-weight: 600;
    padding: 0.7rem;
    font-size: 1rem;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
} */
.tdForm {
  color: var(--main-color-blue);
  /* border-bottom: 1px solid var(--main-color-grey); */
}

.thForm {
  color: var(--main-color-blue);
  border-bottom: 1px solid var(--main-color-grey);
}

.tableIcon {
  background-color: var(--main-color-grey);
  padding: 8px 10px;
  border-radius: 10px;
  text-decoration: none;
  color: var(--main-color-blue);
  font-size: 14px;
  font-family: "Wigrum-Medium";
  transition: 0.3s;
  height: 1.5rem;
  margin-right: 8px;
  cursor: pointer;
}

.tableIcon path {
  fill: var(--main-color-orange);
}

.tableIcon:hover {
  background-color: var(--main-color-blue);
  color: white;
}

.descIcons path {
  fill: var(--main-color-orange);
  /* color: #C8C6Af; */
}

.descIcons {
  height: 1.5rem;
}

.editProspectGroup {
  display: flex;
}

.editIcon path {
  fill: var(--main-color-orange);
}

.editIcon {
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 10px;
}

.editIconPop path {
  color: #cbccd0;
}

.editIconPop {
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 10px;
}

/* CONTACT CSS */

.allContactContent>.contactContent {
  /* border: blue 1px solid; */
  margin-bottom: 2rem;
}

.allContactContent>.contactContentResum {
  background-color: var(--main-color-blue);
  padding: 1rem;
  border-radius: 15px;
  margin-bottom: 2rem;
}

.allContactContent>.contactContentResum>.title {
  color: white;
  font-family: "Wigrum-medium";
  font-size: 20px;
}

/* .allContactContent>.contactContentResum>div.icon svg path {
    background-color: white;
    color: white !important;
} */

.allContactContent>.contactContentResum>.p {
  color: white;
  font-family: "Wigrum-light";
}

.allContactContent>.contactContent>.inputs>.clientInfos {
  margin: 2rem 0;
  background-color: var(--main-color-grey);
  padding: 1.5rem;
  border-radius: 15px;
}

.allContactContent>.contactContent>.inputs>.clientInfos>.form-group {
  display: flex;
  grid-template-columns: 2fr 1fr;
  flex-wrap: wrap;


  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .allContactContent>.contactContent>.inputs>.clientInfos>.form-group {
    display: flex;
    flex-direction: column;
  }

}

.allContactContent>.contactContent>.inputs>.clientInfos:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}

.allContactContent>.contactContent>.inputs>.clientInfos>.form-group>.input {
  width: 45%;
  border: 0;
  outline: 0;
  border-bottom: 1px solid var(--main-color-blue);
  color: var(--main-color-blue);
  margin: 10px 0px;
  font-size: 16px;
  background-color: transparent;
  margin: 10px 0px;
  text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
  .allContactContent>.contactContent>.inputs>.clientInfos>.form-group>.input {
    width: 100%;
  }

}

.inputDiv {
  width: 45%;
  margin: 10px 0px;
  display: flex;
}

.Asterisk {
  margin-right: 5px;
  display: none;
  color: red;
}

.required {
  border-bottom: 1px solid var(--main-color-blue-lighter) !important;
}

.requiredInfos {
  display: flex;
  align-items: center;
  width: fit-content;
}

.underlineRequired {
  width: 25px;
  background-color: var(--main-color-blue-lighter);
  height: 2px;
  border-radius: 10px;
  display: flex;
  margin-left: 1rem;
}

.allContactContent>.contactContent>.inputs>.clientInfos>.form-group>.select {
  all: unset;
  width: 45%;
  border-bottom: 1px solid var(--main-color-blue);
  color: var(--main-color-blue);
  margin: 10px 0px;
}

@media screen and (max-width: 768px) {
  .allContactContent>.contactContent>.inputs>.clientInfos>.form-group>.select {
    width: 100%;
  }

}

.required {
  border-bottom: 1px solid var(--main-color-blue-lighter) !important;
}

/* checkBox */

.allCheck {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  gap: 10px;
}

.checkBox {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.check {
  display: none;
}

.checkStyle {
  min-width: 30px;
  min-height: 30px;
  display: flex;
  border-radius: 25%;
  /* height: 20px; */
  width: fit-content;
  align-items: center;
  padding: 3px;
  justify-content: center;
  background-color: white;
  border: 1px solid var(--main-color-blue);
  margin-right: 5px;
}

.checkStyle path {
  fill: var(--main-color-orange);
}

.checkStyle.hovered {
  background-color: var(--main-color-blue-lighter);
  /* background-color: red; */
}

.checkStyle.checked {
  background-color: var(--main-color-blue);
}

.blabla {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.erase {
  margin-right: 10px;
  position: relative;
  opacity: 1;
  transition: opacity 1s;
}

.hehe {
  opacity: 0;
  transition: opacity 1.5s;
}

.icon-container {
  position: relative;
  left: 0;
  /* background-color: white; */
  width: 70px;
  border-radius: 20%;
  transition: left 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.clicked {
  left: -65px;
  /* Modifier la valeur en fonction de la distance de déplacement souhaitée */
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.clicked .erase {
  opacity: 0;
}

/* CONTRACT */

.contract>.block>.infos>hr {
  border-style: none;
  border-top: 3px solid var(--main-color-blue);
  margin: 15px 0px;
}

.contract>.block>.infos>.infos-block div {
  margin: 15px 0px;
}

.contract>.block>.infos>.infos-block>.invoice>.invoiceSearch {
  margin: 2px 6px 2px 6px;
}

.contract>.block>.infos>.infos-block h4 {
  margin: 5px 0px;
}

.quote>p {
  text-transform: uppercase;
}

.quote>p>span {
  text-transform: none;
}

.contract>.block>.infos>.infos-block>.subject input {
  padding: 5px;
  width: 100%;
  border-radius: var(--main-border-radius);
}

.contract>.block>.infos>.infos-block>.interlocutor div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}

.contract>.block>.infos>.infos-block>.interlocutor div a {
  padding: 8px;
  background-color: var(--main-color-grey);
  color: var(--main-color-blue);
  border-radius: 6px;
  text-decoration: none;
  font-size: 12px;
  transition: 0.3s;
}

.contract>.block>.infos>.infos-block>.interlocutor div a:hover {
  background-color: var(--main-color-blue);
  color: white;
}

.contract>.block>.infos>.infos-block>.interlocutor select {
  all: unset;
  width: 100%;
  border-bottom: 1px solid black;
}

.contract>.block>.infos>.infos-block>.contact div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}

.contract>.block>.infos>.infos-block>.contact div a {
  padding: 8px;
  background-color: var(--main-color-grey);
  color: var(--main-color-blue);
  border-radius: 6px;
  text-decoration: none;
  font-size: 12px;
  transition: 0.3s;
}

.contract>.block>.infos>.infos-block>.contact div a:hover {
  background-color: var(--main-color-blue);
  color: white;
}

.contract>.block>.infos>.infos-block>.contact select {
  all: unset;
  width: 100%;
  border-bottom: 1px solid black;
}

.contract>.block>.infos>.infos-block>.contact>p {
  width: 100%;
  border-bottom: 1px solid black;
}

.contract>.block>.infos>.infos-block>.contact-search a {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding: 16px 8px;
  width: 100%;
  background-color: var(--main-color-grey);
  color: var(--main-color-blue);
  border-radius: var(--main-border-radius);
}

.contract>.block>.infos>.infos-block>.contact-search p {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding: 16px 8px;
  width: 100%;
  background-color: var(--main-color-grey);
  color: var(--main-color-blue);
  border-radius: var(--main-border-radius);
}

.contract>.block>.infos>.infos-block>.contact-search a img {
  float: right;
}

.contract>.block>.contract-body>.container>.group {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.contract>.block>.contract-body>.container>.group>.group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contract>.block>.contract-body>.container>.group>.group-header>p {
  color: var(--main-color-blue);
  font-family: "Wigrum-Bold";
}

.contract>.block>.contract-body>.container>.group>.group-header>div:first-child {
  display: flex;
}

.contract>.block>.contract-body>.container>.group>.group-header>div:first-child>div {
  display: flex;
  align-items: center;
}

.contract>.block>.contract-body>.container>.group>.group-header>div:first-child>div a {
  color: white;
  background-color: var(--main-color-blue);
  padding: 3px 5px;
  border-radius: 6px;
  margin: 0px 2px;
}

.contract>.block>.contract-body>.container>.group>.group-header>div:first-child>div p {
  color: white;
  background-color: var(--main-color-blue);
  padding: 3px 5px;
  border-radius: 6px;
  margin: 0px 2px;
}

.contract>.block>.contract-body>.container>.group>.group-header>div:first-child>div p:hover {
  cursor: pointer;
}

.contract>.block>.contract-body>.container>.group>.group-header>div:first-child>div a svg path {
  fill: white;
}

.contract>.block>.contract-body>.container>.group>.group-header>div:first-child>div p svg path {
  fill: white;
}

.contract>.block>.contract-body>.container>.group>.group-header>div:first-child>p {
  margin-left: 10px;
  color: var(--main-color-blue);
  font-family: "Wigrum-Bold";
}

.contract>.block>.contract-body>.container>.group>.group-header>div:first-child>input {
  color: var(--main-color-blue);
  font-family: "Wigrum-Bold";
  width: 150px;
  height: 30px;
  font-size: 15px;
  margin-left: 5px;
  border-radius: 4px;
  padding-left: 5px;
}

.contract>.block>.contract-body>.container>.group>.line {
  display: flex;
  flex-direction: column;
  background-color: var(--main-color-grey);
  padding: 1rem;
  border-radius: 10px;
  margin: 10px 0px;
  position: relative;
}

.contract>.block>.contract-body>.container>.group>.line:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}

.contract>.block>.contract-body>.container>.group hr {
  border-style: none;
  border-top: 3px solid var(--main-color-blue);
  width: 100%;
  margin: 25px 0 0 0;
}

.contract>.block>.contract-body>.container>.group>.line input {
  border: 0;
  outline: 0;
  border-bottom: 1px solid var(--main-color-blue);
  color: var(--main-color-blue);
  width: 100%;
  margin: 10px 0px;
  font-size: 16px;
  background-color: transparent;
  margin: 10px 0px;
}

.contract>.block>.contract-body>.container>.group>.line select {
  all: unset;
  width: 100%;
  border-bottom: 1px solid var(--main-color-blue);
  color: var(--main-color-blue);
  margin: 10px 0px;
}

.contract>.block>.contract-body>.container>.group>.line textarea {
  border: none;
  outline: none;
  margin: 10px 0px;
  padding: 10px;
  height: 100px;
  color: var(--main-color-blue);
  border-radius: 6px;
  max-width: 100%;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component1>.options>.line-amount {
  display: flex;
  align-items: center;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component1>.options>.line-amount p.ht {
  font-size: 15px;
  width: 100%;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component1>.options>.line-amount p.ht span {
  float: right;
  font-family: "Wigrum-Bold";
  font-size: 16px;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options>.buttons {
  display: flex;
  align-items: center;
}

/* .contract>.block>.contract-body>.container>.group>.line>.line-component2>.options>.buttons a {} */

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options>.tri a {
  color: var(--main-color-blue);
  padding: 4px 6px;
  border: 1px solid var(--main-color-blue);
  padding: 4px 6px;
  border-radius: 4px;
  text-decoration: none;
  transition: 0.3s;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options>.tri a:hover svg {
  filter: brightness(0) invert(1);
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options>.options-one {
  display: flex;
  align-items: center;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options>.options-one a {
  color: var(--main-color-blue);
  padding: 4px 6px;
  border: 1px solid var(--main-color-blue);
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options>.options-two {
  width: 70%;
  display: flex;
  justify-content: flex-end;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options a {
  padding: 6px 8px;
  border-radius: 6px;
  text-decoration: none;
  transition: 0.3s;
  font-size: 13px;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options a:hover {
  background-color: var(--main-color-blue);
  color: white;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options a.cart {
  display: flex;
  align-items: center;
  background-color: white;
  color: var(--main-color-blue);
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options a.cart:hover {
  background-color: var(--main-color-blue);
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options a.cart:hover p {
  color: white;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options a.cart:hover img {
  filter: brightness(0) invert(1);
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options a.garbage {
  background-color: red;
  color: white;
  margin-left: 10px;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options a.garbage svg path {
  fill: white;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options a.garbage img {
  filter: brightness(0) invert(1);
}

.contract>.block>.contract-body>.container>.group>.line>.line-component2>.options a.garbage:hover {
  background-color: rgb(208, 20, 20);
}

.contract>.block>.contract-body>.container>.group>.line>.line-component1>.unit>div {
  display: flex;
  align-items: center;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component1>.unit>div p {
  width: 100px;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component1>.unit>div input {
  text-align: flex-end;
}

.contract>.block>.contract-body>.container>.group>.line>.line-component1>.unit>div select {
  text-align: flex-end;
}

.contract>.block>.contract-body>.container>.group>.line p.num {
  border-radius: 50%;
  background-color: white;
  color: var(--main-color-blue);
  font-size: 16px;
  font-weight: bold;
  width: 30px;
  height: 30px;
  text-align: center;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contract>.block>.contract-body>.container>.group>.line>.tri {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 10px;
  left: 10px;
  z-index: 5;
}

.contract>.block>.contract-body>.container>.group>.line>.tri svg {
  background-color: white;
  margin: 5px 0px;
  padding: 8px;
  border-radius: 10%;
}

.contract>.block>.contract-body>.container>.group>.line>.tri svg path {
  fill: var(--main-color-blue);
}

.contract>.block>.contract-body>.container>.group>.line>.v-line {
  border-left: 2px solid var(--main-color-blue);
  top: 50px;
  left: 25px;
  bottom: 100px;
  position: absolute;
}

.contract>.block>.contract-body>.container>.group>.group-options {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
}

.contract>.block>.contract-body>.container>.group>.group-options>.new-line {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--main-color-grey);
  padding: 10px;
  border-radius: 10px;
  width: 50%;
  cursor: pointer;
  margin-right: 5px;
}

.contract>.block>.contract-body>.container>.group>.group-options>.new-line:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}

.contract>.block>.contract-body>.container>.group>.group-options>.new-line>* {
  color: var(--main-color-blue);
  margin: 0px 5px;
}

.contract>.block>.contract-body>.container>.group>.group-options>.new-line svg {
  padding: 8px;
  background-color: var(--main-color-blue);
  border-radius: 50%;
}

.contract>.block>.contract-body>.container>.group>.group-options>.new-line svg path {
  fill: white;
}

.contract>.block>.contract-body>.container>.group>.group-options>.delete-group {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--main-color-grey);
  padding: 10px;
  border-radius: 10px;
  width: 50%;
  cursor: pointer;
  margin-left: 5px;
}

.contract>.block>.contract-body>.container>.group>.group-options>.delete-group:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}

.contract>.block>.contract-body>.container>.group>.group-options>.delete-group>* {
  color: var(--main-color-blue);
  margin: 0px 5px;
}

.contract>.block>.contract-body>.container>.group>.group-options>.delete-group svg {
  padding: 8px;
  background-color: var(--main-color-red);
  border-radius: 50%;
}

.contract>.block>.contract-body>.container>.group>.group-options>.delete-group svg path {
  fill: white;
}

.new-group {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--main-color-blue);
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 50px;
  cursor: pointer;
  width: 100%;
}

.new-group:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}

.new-group>* {
  color: white;
  margin: 0px 5px;
}

.new-group svg {
  padding: 8px;
  background-color: white;
  border-radius: 50%;
}

.new-group svg path {
  fill: var(--main-color-blue);
}

.contract>.block>.contract-body>.container>.price {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
}

.contract>.block>.contract-body>.container>.price>.bold-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: var(--main-color-blue);
  padding: 10px;
  border-radius: 8px;
  margin: 5px 0px;
}

.contract>.block>.contract-body>.container>.price>.bold-block p {
  color: white;
  font-size: 18px;
  font-family: "Wigrum-Medium";
}

.contract>.block>.contract-body>.container>.price>.sub-total-ht {
  display: flex;
  justify-content: space-between;
  background-color: var(--main-color-blue);
  padding: 10px;
  border-radius: 8px;
}

.contract>.block>.contract-body>.container>.price>.sub-total-ht p {
  color: white;
  font-size: 18px;
  font-family: "Wigrum-Medium";
}

.contract>.block>.contract-body>.container>.price>.discount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
}

.contract>.block>.contract-body>.container>.price>.discount>div {
  flex-direction: column;
}

.contract>.block>.contract-body>.container>.price>.discount>div p {
  color: var(--main-color-blue);
  font-size: 16px;
}

.contract>.block>.contract-body>.container>.price>.discount>div input {
  width: 120px;
  height: 30px;
  border-radius: 4px;
  font-size: 16px;
  padding: 5px;
}

.discount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
}

.discount>div {
  flex-direction: column;
}

.discount>div p {
  color: var(--main-color-blue);
  font-size: 16px;
}

.discount>div input {
  width: 120px;
  height: 30px;
  border-radius: 4px;
  font-size: 16px;
  padding: 5px;
}

.aside {
  grid-column: 2;
  grid-row: 1 / 3;
}

@media screen and (max-width: 1024px) {
  .aside {
    grid-column: 1;
    grid-row: 3;
  }

}

.contract>.block>.contract-body>.container>.price>.tva {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
}

/* .contract > .block > .contract-body > .container > .price > .tva> div p {
display: flex;
align-items: center;
} */
.contract>.block>.contract-body>.container>.price>.tva-rate {
  width: 100px;
}

.contract>.block>.contract-body>.container>.price>.all-tva {
  margin-right: 100px;
}

.contract>.block>.contract-body>.container>.price>.tva>div {
  display: flex;
  justify-content: space-between;
  margin: 4px 0px;
}

.contract>.block>.contract-body>.container>.price>.tva>div p {
  color: var(--main-color-blue);
  font-size: 16px;
  display: flex;
}

.depositTvaAmount {
  min-width: max-content;
}

.contract>.block>.contract-body>.container>.price>.payment {
  display: flex;
  flex-direction: column;
  margin: 25px 0px;
}

.contract>.block>.contract-body>.container>.price>.payment>* {
  margin: 5px 0px;
  color: var(--main-color-blue);
}

.contract>.block>.contract-body>.container>.price>.payment input {
  border: 0;
  outline: 0;
  border-bottom: 1px solid var(--main-color-blue);
  font-size: 16px;
}

.payment {
  display: flex;
  flex-direction: column;
  margin: 25px 0px;
}

.payment>* {
  margin: 5px 0px;
  color: var(--main-color-blue);
}

.payment input {
  border: 0;
  outline: 0;
  border-bottom: 1px solid var(--main-color-blue);
  font-size: 16px;
  width: 20%;
}

.payment div {
  display: flex;
  flex-direction: row;
}

.contract>.block>.contract-body>.container>.price>.deposits {
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
}

.contract>.block>.contract-body>.container>.price>.deposits>* {
  margin: 5px 0px;
  color: var(--main-color-blue);
}

.contract>.block>.contract-body>.container>.price>.deposits a {
  text-decoration: none;
  background-color: var(--main-color-orange);
  color: white;
  border-radius: 6px;
  padding: 15px 10px;
}

.contract>.block>.contract-body>.container>.price>.deposits a span {
  font-family: "Wigrum-Bold";
  color: white;
}

.deposits {
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
}

.deposits>* {
  margin: 5px 0px;
  color: var(--main-color-blue);
}

.deposits a {
  text-decoration: none;
  background-color: var(--main-color-orange);
  color: white;
  border-radius: 6px;
  padding: 15px 10px;
}

.deposits a span {
  font-family: "Wigrum-Bold";
  color: white;
}

.comment {
  display: flex;
  flex-direction: column;
  margin: 25px 0px;
  color: var(--main-color-blue);
}

.comment>h3 {
  font-size: 24px;
  font-family: "Wigrum-Regular";
  color: var(--main-color-blue);
}

@media screen and (max-width: 1024px) {
  .comment>h3 {
    font-size: 20px;
  }
}

.comment>.line {
  border: none;
  height: 3px;
  margin: 26px 0;
  background-color: #666666;
}

textarea {
  border: 1px solid var(--main-color-blue);
  outline: none;
  height: 100px;
  padding: 10px;
  border-radius: 6px;
  max-width: 100%;
}

.contract>.block>.contract-body>.container>.comment {
  display: flex;
  flex-direction: column;
  margin: 25px 0px;
}

.title {
  margin-bottom: 20px;
  font-size: 20px;
  text-transform: uppercase;
}

.contract>.block>.contract-body>.container>.comment>* {
  margin: 5px 0px;
  color: var(--main-color-blue);
}

.contract>.block>.contract-body>.container>.comment textarea {
  border: 1px solid var(--main-color-blue);
  outline: none;
  height: 100px;
  padding: 10px;
  border-radius: 6px;
}

.margin {
  display: flex;
  flex-direction: column;
  margin: 25px 0px;
  background-color: var(--main-color-orange);
  border-radius: 10px;
  padding: 10px;
}

.margin>.margin-amounts h3 {
  text-align: center;
}

.margin>.margin-amounts div {
  margin: 8px 0px;
}

.margin>.margin-amounts div input {
  border: none;
  outline: 0;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  color: var(--main-color-blue);
  font-family: "Wigrum-Medium";
  padding-left: 5px;
  margin-top: 8px;
}

.margin>.margin-amounts>div>p.number {
  font-size: 24px;
  font-family: "Wigrum-Bold";
}

.margin * {
  color: white;
}

.margin>.footer-margin {
  margin: 15px 0px;
}

.margin>.footer-margin div:first-child {
  margin-bottom: 15px;
}

.margin>.footer-margin p.number {
  font-size: 22px;
  font-family: "Wigrum-Bold";
}

/***** TABLEAU DE BORD ******/

.dashboard-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  margin: 0px 10px;
}

.dashboard-content div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  width: 100%;
  height: 500px;
  border-radius: 8px;
  margin: 10px 0px;
}

/* .dashboard-content div:first-child {}

.dashboard-content div:last-child {} */

.dashboard-content .welcome-card {
  background-color: var(--main-color-orange);
}

.dashboard-content .chart {
  background-color: #dddddd;
}

.margin-component {
  width: 100%;
  background-color: var(--main-color-grey);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.margin-component>div {
  padding: 10px;
}

.margin-component>.margin-amounts>* {
  color: #05162f;
}

.margin-component>.margin-amounts>div {
  margin: 8px 0px;
}

.margin-component>.margin-amounts input {
  border: none;
  border-radius: 6px;
  height: 40px;
  width: 60%;
  font-size: 18px;
  padding-right: 8px;
}

.margin-component>.margin-results>div {
  margin: 8px 0px;
}

.margin-component>.margin-results p {
  font-family: "Wigrum-Bold";
  margin-bottom: 15px;
}

.margin-component>.margin-results h3 {
  color: var(--main-color-orange);
  font-size: 22px;
  margin: 0px;
}

/**** PARAMETRES *******/
.list>.content>p {
  font-size: 14px;
  margin: 20px 10px;
}

.list>.content>p span {
  font-family: "Wigrum-Bold";
}

.list>.content>.primary-settings {
  margin: 25px 0px;
}

.list>.content>.primary-settings>.fields {
  padding: 15px;
}

.list>.content>.primary-settings>h3 {
  margin-left: 15px;
}

.list>.content>.primary-settings input {
  border: 1px solid var(--main-color-blue);
  border-radius: var(--main-border-radius);
  font-size: 16px;
  width: 100%;
  height: 35px;
  padding: 0px 5px;
}

.list>.content>.primary-settings select {
  width: 100%;
  border-radius: var(--main-border-radius);
  font-size: 16px;
  height: 35px;
  border: none;
  border: 1px solid var(--main-color-blue);
}

.list>.content>.primary-settings .types div {
  display: flex;
  width: 100%;
  align-items: center;
  height: 35px;
}

.list>.content>.primary-settings .types input[type="radio"] {
  height: 15px;
  width: 15px;
  background-color: white;
  margin: 0px 5px;
}

.list>.content>.primary-settings .types label {
  font-size: 15px;
  font-weight: 300;
}

.list>.content>.primary-settings>.fields .field {
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
}

.list>.content>.primary-settings>.fields p {
  font-family: "Wigrum-Medium";
  font-size: 14px;
}

.list>.content>.primary-settings>.fields .field.siret-ape {
  display: flex;
  flex-direction: row;
}

.list>.content>.primary-settings>.fields .field.siret-ape>.siret {
  width: 65%;
  margin-right: 4px;
}

.list>.content>.primary-settings>.fields .field.siret-ape>.ape {
  width: 35%;
  margin-left: 4px;
}

.list>.content>.primary-settings>.fields .field>.cp-ville {
  display: flex;
}

.list>.content>.primary-settings>.fields .field>.cp-ville>.cp {
  width: 25%;
  margin-right: 4px;
}

.list>.content>.primary-settings>.fields .field>.cp-ville>.ville {
  width: 75%;
  margin-left: 4px;
}

.list>.content>.primary-settings>.fields .field.designation textarea {
  height: 80px;
  border: 1px solid var(--main-color-blue);
  width: 100%;
  border-radius: var(--main-border-radius);
  padding: 5px;
  resize: vertical;
}

.list>.content>.primary-settings>.fields .field>.logo {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.list>.content>.primary-settings>.fields .field>.logo>.buttons {
  display: flex;
}

.list>.content>.primary-settings>.fields .field>.logo>.buttons a {
  width: 50%;
  text-decoration: none;
  background-color: #05162f;
  color: white;
  padding: 5px;
  border-radius: var(--main-border-radius);
  width: 100%;
  margin: 10px 5px;
  font-size: 14px;
}

.upload-file-logo,
.delete-logo,
.upload-file-cgv,
.delete-cgv {
  width: 50%;
  text-decoration: none;
  background-color: #05162f;
  color: white;
  padding: 5px;
  border-radius: var(--main-border-radius);
  width: 100%;
  margin: 10px 5px;
  font-size: 14px;
  cursor: pointer;
}

#upload-logo,
#upload-cgv {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.logo img {
  width: 40%;
}

.list>.content>.primary-settings>.fields .field>.logo>.img img {
  width: 200px;
  height: auto;
}

.list>.content>.primary-settings>.fields .field>a {
  text-decoration: none;
  padding: 5px;
  background-color: var(--main-color-blue);
  color: white;
  border-radius: var(--main-border-radius);
  width: auto;
  text-align: center;
}

.list>.content>.primary-settings>.interlocutors {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.list>.content>.primary-settings>.interlocutors>.interlocutor {
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
}

.list>.content>.primary-settings>.interlocutors>hr {
  border: none;
  border-bottom: 3px solid #05162f;
  width: 100%;
  margin: 8px 0px;
}

.list>.content>.primary-settings>.interlocutors>.add a {
  text-decoration: none;
  padding: 5px;
  border-radius: var(--main-border-radius);
  background-color: #05162f;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-top: 10px;
}

.list>.content>.primary-settings>.interlocutors>.add a>svg {
  margin-right: 5px;
}

.list>.content>.primary-settings>.interlocutors>.interlocutor>div {
  display: flex;
  flex-direction: column;
}

.list>.content>.primary-settings>.interlocutors>.interlocutor>.button a {
  text-decoration: none;
  padding: 5px;
  background-color: #ff2a2a;
  color: white;
  border-radius: var(--main-border-radius);
  text-align: center;
  margin-top: 8px;
}

.list>.content>.primary-settings>.interlocutors>.interlocutor>.button a svg path {
  fill: white;
}

.list>.content>.historic-quote {
  margin: 0px 15px;
}

.list>.content>.historic-quote>.cards-list>.card {
  width: 100%;
}

/******* LOGIN *******/

/*.loginHeader {*/
/*  background-color: var(--main-color-blue);*/
/*  width: 100%;*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  position: fixed;*/
/*}*/

.register {
  box-sizing: border-box;
  display: block;
  text-decoration: none;
  width: 100%;
  background-color: var(--main-color-orange);
  color: white;
  border-radius: 6px;
  padding: 15px;
  text-align: center;
  margin: 10px 0px;
  border: none;
  cursor: pointer;
  margin-right: 1rem;
}

.headerLogo {
  border-right: var(--main-color-dark-gray) 1px solid;
  padding: 1rem;
  padding-right: 3rem;
}

.headerLeftContent {
  display: flex;
  align-items: center;
}

.headerMailBox {
  border-left: var(--main-color-dark-gray) 1px solid;
  border-right: var(--main-color-dark-gray) 1px solid;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.headerMailIcon {
  height: 1.5rem;
  background-color: var(--main-color-orange);
  padding: 7px;
  border-radius: 100%;
}

.headerMailIcon path {
  color: white;
}

.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loginContent {
  background-color: var(--main-color-grey);
  width: 95%;
  border-radius: 10px;
  margin-top: 5rem;
  height: 60%;
  padding-left: 2rem;
  padding-right: 2rem;
}

.welcome {
  font-size: 2rem2px;
  padding: 2rem;
  text-align: center;
  font-family: "Wigrum-Light";
  font-weight: 400;
}

.welcome>span {
  font-weight: 900;
}

.loginLine {
  border: none;
  border-bottom: 2px solid #c4c4c4;
  width: 100%;
}

.loginBlock {
  display: flex;
  flex-direction: row;
  margin-top: 5%;
  justify-content: space-around;
  margin-bottom: 5%;
}

.loginText {
  width: 50%;
}

.loginTitle {
  font-size: 1.5rem;
  text-align: flex-end;
}

.loginTitle>span {
  font-size: 1.7rem;
  font-family: "Wigrum-bold";
}

.loginSubTitle {
  font-size: 1.2rem;
  padding: 25px 0px;
  text-align: flex-end;
  font-family: "Wigrum-medium";
  font-style: italic;
  font-weight: 400;
}

.loginTxt {
  text-align: flex-end;
}

.loginTxt>span {
  font-family: "Wigrum-bold";
}

.loginLabel {
  margin-left: 1rem;
}

.loginInput {
  margin-top: 5px;
  border: none;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  font-size: 18px;
  padding: 5px;
}

.subButton {
  box-sizing: border-box;
  display: block;
  text-decoration: none;
  width: 100%;
  background-color: var(--main-color-orange);
  color: white;
  border-radius: 6px;
  padding: 15px;
  text-align: center;
  margin: 10px 0px;
  border: none;
  cursor: pointer;
}

.subTxt {
  text-align: flex-end;
  font-size: 0.8rem;
}

.subTxt:hover {
  color: var(--main-color-orange);
  cursor: pointer;
}

/*.loginFooter {*/
/*  background-color: var(--main-color-blue);*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  padding: 1rem;*/
/*  align-items: center;*/
/*  width: 100%;*/
/*  justify-content: space-between;*/
/*}*/

.loginFooterLogo {
  height: 85%;
  margin-right: 2rem;
}

.lofinFooterRight {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.lofinFooterTxt {
  color: white;
  font-size: 0.7rem;
  margin-right: 2rem;
}

.loginFooterMore {
  color: white;
  text-decoration: none;
  background-color: #4c5d6d;
  padding: 5px;
  border-radius: 7px;
}

.loginFooterMore>svg {
  height: 0.8rem;
}

.loginFooterMore>svg path {
  color: white;
}

.main>.sign-in-container>.sign-in-promo {
  display: none;
}

/******* ACOMPTE *******/
.contract>.block>.contract-body>.container>.deposit {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}

.contract>.block>.contract-body>.container>.deposit>div>div {
  display: flex;
  align-items: center;
}

.contract>.block>.contract-body>.container>.deposit>div {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0px;
  justify-content: center;
  align-items: center;
}

.contract>.block>.contract-body>.container>.deposit>div label {
  width: 100%;
  text-align: center;
}

.contract>.block>.contract-body>.container>.deposit>div input {
  width: 200px;
  border-radius: var(--main-border-radius);
  padding: 5px;
  font-size: 18px;
  font-family: "Wigrum-Bold";
}

.contract>.block>.infos>.infos-block>.contact p.ref span {
  font-family: "Wigrum-Bold";
}

.contract>.block>.infos>.infos-block>.contact p.name {
  font-weight: 400;
}

.contract>.block>.infos>.infos-block>.contact p.address {
  font-weight: 200;
  font-style: italic;
  font-family: "Wigrum-Light";
}

/******* ITEMS LISTE *******/

/* Search Filter */
/* 
.searchFilter {
  width: 88%;
  margin: 15px 6%;
  border-radius: var(--main-border-radius);
  background-color: var(--main-color-grey);
  padding: 1rem;
  .title {
    margin-top: 1rem;
    user-select: none;
    margin-bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .titleSpan {
      display: flex;
      align-items: center;
      font-family: "Wigrum-light";
      color: var(--main-color-blue);
      .loopIcon {
        margin-right: 1rem;
        display: none;
      }
    }
  }

  .searchFilterForm {
    display: none;
    .inputContent {
      display: flex;
      flex-direction: column;
      .input {
        user-select: none;
        margin-top: 1rem;
        margin-right: 1rem;
        width: 100%;
        height: 35px;
        border: none;
        padding-left: 1rem;
        border-radius: 5px;
      }
      .select {
        user-select: none;
        margin: 1rem 0;
        width: fit-content;
        border: none;
        height: 35px;
        padding-left: 1rem;
        border-radius: var(--main-border-radius);
      }
    }
  }
  .active {
    display: flex;
    flex-direction: column;
  }
  .searchFilterActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .searchFilterReset {
      height: 2rem;
      cursor: pointer;
    }
    .searchFilterReset:active {
      transform: scale(0.9) rotate(360deg);
      transition: transform 0.2s;
    }
    .searchSubmitButton {
      user-select: none;
      background-color: var(--main-color-orange);
      color: white;
      border-radius: 6px;
      padding: 10px;
      text-align: center;
      border: none;
      cursor: pointer;
      width: fit-content;
    }
  }
} */

.list>.search-bar p {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding: 16px 8px;
  width: 88%;
  margin: 15px 6%;
  background-color: var(--main-color-grey);
  color: var(--main-color-blue);
  border-radius: var(--main-border-radius);
  cursor: pointer;
}

.list>.search-bar svg {
  margin-right: 1rem;
}

.list>.search-bar span svg {
  margin-left: 1rem;
}

.list>.search-bar span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cards-list>.card>.families p:last-child {
  color: #797979;
  margin-left: 5px;
  font-size: 13px;
}

/******* DASHBOARD ADMIN *****/

.dashboard-admin-content {
  display: flex;
  flex-direction: column;
}

.dashboard-admin-content>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0px;
}

.dashboard-admin-content>div>.title {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: var(--main-color-blue);
  color: white;
  border-radius: var(--main-border-radius);
}

.dashboard-admin-content>div>.title svg {
  margin-right: 5px;
  font-size: 20px;
}

.dashboard-admin-content>div>.title svg path {
  fill: white;
}

.dashboard-admin-content>div h2 {
  font-weight: 400;
  font-size: 20px;
  color: white;
}

/* calendar */
.abso {
  position: absolute;
  z-index: 1;
  /* background: rgba(191, 191, 195, 0.392); */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.react-calendar {
  position: absolute;
  z-index: 1500;
  top: 80px;
  border-radius: 5px;
  border: 1px solid black;
  background: var(--main-color-grey);
}

.react-calendar__tile {
  border-radius: 5px;
}

.react-calendar__tile:active {
  background: var(--main-color-orange);
}

.react-calendar.hide {
  display: none;
}

.main.pop {
  position: relative;
  min-height: 100vh;
}

.modal-pop {
  background-color: white;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-pop.display-block {
  display: block;
}

.modal-pop.display-none {
  display: none;
}

.main.pop>svg {
  position: fixed;
  top: 15px;
  right: 30px;
  cursor: pointer;
  z-index: 81;
}

.card.pop {
  cursor: pointer;
}

.list>.toolbar.pop {
  top: 0px;
}

.loader {
  border: 5px solid #f4f3f3;
  border-top: 5px solid #fdab0d;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s ease-in-out infinite;
  position: absolute;
  z-index: 10000;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -60%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/***** MAIL POP ******/
.mail-pop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10003;
}

.mail-pop>.mail-pop-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  border-radius: 8px;
}

.mail-pop>.mail-pop-content>.mail-fields {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mail-pop>.mail-pop-content>.mail-fields>form>* {
  width: 100%;
  border-radius: 4px;
  padding: 6px;
}

.mail-pop>.mail-pop-content>.mail-fields>form textarea {
  height: 200px;
}

/* PERSONAL CATALOG */

.personalCatalog {
  display: flex;
  align-items: center;

  .personalCatalogOption {
    background-color: var(--main-color-grey);
    border: var(--main-color-dark-gray) 1px solid;
    display: flex;
    align-items: center;
    padding: 5px;
    position: relative;
    height: fit-content;
    border-radius: 5px;
    cursor: pointer;

    .options {
      z-index: 100 !important;
      top: 100%;
      left: 0;
      display: none;
      position: absolute;
      background-color: white;
      border-radius: 5px;
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
      padding: 10px;

      .button {
        cursor: pointer;
        margin-top: 5px;
      }

      .button:hover {
        text-decoration: underline;
      }
    }
  }

  .formDiv {
    margin-right: 10px;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      border-radius: 5px;
      padding: 5px;
    }

    .button {
      height: 1.5rem;
      margin-right: 10px;
      cursor: pointer;
    }

    .button:hover {
      transform: scale(1.2);
      transition: transform 0.2s ease-in-out;

      path {
        fill: green;
      }
    }

    .persoCataSelect {
      width: 100%;
      border-radius: 5px;
      padding: 5px;
      margin-right: 5px;
    }
  }
}

.personalCatalogOption:hover .options {
  display: flex;
  flex-direction: column;
}

/* END PERSONAL CATALOG */

/********* CATALOGUES *********/

.catalogList {
  position: absolute;
  z-index: 1 !important;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
}

.articleTitle {
  margin-bottom: 2rem;
}

.articleCard {
  cursor: pointer;
  border: var(--main-color-grey) 2px solid;
  border-radius: var(--main-border-radius);
  margin-bottom: 1rem;
  padding: 1%;

  .content {
    padding: 3px;

    >svg {
      margin-right: 1rem;
      height: 1.2rem;
      width: 30px;

      >path {
        fill: var(--main-color-orange);
      }
    }
  }

  .contentGroup {
    display: flex;
    align-items: center;
    padding: 3px;

    >svg {
      height: 1.2rem;
      margin-right: 1rem;
      width: 30px;

      >path {
        fill: var(--main-color-orange);
      }
    }
  }

  .articleCard:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px;
  }

  .priceGroup {
    display: flex;

    >p {
      margin-right: 1rem;
    }
  }
}

.articleCard:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px;
}

.tools {
  .switchItemButton {
    cursor: pointer;
    color: white;
    border-radius: 5px;
    padding: 5px;
    user-select: none;
  }

  .generalItemButton {
    background-color: var(--main-color-orange);
  }

  .personalItemButton {
    background-color: var(--main-color-blue-lighter);
  }

  .switchItemButton:hover {
    background-color: var(--main-color-blue);
  }

  .createItemButton:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.itemPop {
  .popHeader {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;

    .options {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 1rem;
        height: 1.5rem;
        cursor: pointer;
      }

      .copy:hover {
        >path {
          fill: var(--main-color-blue-lighter);
        }
      }

      .edit:hover {
        >path {
          fill: var(--main-color-orange-light);
        }
      }
    }
  }

  .cross {
    cursor: pointer;
    height: 2rem;
  }

  .cross:hover {
    transform: scale(1.2) rotate(180deg);
    transition: transform 0.2s ease-in-out 0.1s;
  }

  .articleContent {
    input {
      border: 1px solid lightgray;
    }

    .name {
      margin-bottom: 2rem;
    }

    .h3 {
      font-size: 1.5rem;
      margin-bottom: 5px;
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 2rem;

      select {
        width: 15rem;
        height: 55px;
      }

      .articleDelete {
        height: 40px;
      }

      .span {
        font-weight: 600;
      }
    }
  }

  .form {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
    }

    .container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 2rem;
      gap: 2rem;
      justify-content: space-between;

      .content {


        .select {
          display: flex;
          /* flex-direction: row; */
          justify-content: space-between;
          gap: 3rem;

          /* input {
          border: none;
          border-radius: var(--main-border-radius);
          height: 35px;
          padding: 0px 5px;
          margin-top: 10px;
          width: 180px !important;
          flex: 2;
          box-sizing: border-box;
        } */
          .CreateItemSupplierButton {
            height: 48px;
            padding: 15px 5px;
            margin-top: 10px;
            font-size: 0.8rem;
            width: 183px !important;
          }
        }
      }

      .content-block {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;

        .content {
          width: 40%;

          .basicInputContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 1rem;
            width: 100%;

            label {
              text-align: start;
              display: flex;
              align-items: center;
              width: fit-content;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .button {
      padding: 10px;
      border-radius: var(--main-border-radius);
      background-color: var(--main-color-orange);
      color: white;
      border: none;
      cursor: pointer;
      margin-top: 2rem;
    }

    .button:hover {
      background-color: var(--main-color-orange-light);
    }
  }
}

.cata-options {
  display: flex;
  flex-direction: row;
}

.list>.toolbar>.button {
  position: relative;
}

.list>.toolbar>.button>a {
  display: flex;
  align-items: center;
}

.list>.toolbar>.button>a>svg {
  height: 2rem;
}

.list>.toolbar>.button>a>span {
  color: white;
  display: none;
}

.list>.toolbar>.button>.dropdown-toggle a {
  border-radius: 8px;
}

.list>.toolbar>.button>.dropdown-toggle>a {
  text-decoration: none;
  padding: 12px 8px;
  margin: 0px 2px;
  background-color: var(--main-color-grey);
  color: var(--main-color-blue);
  display: inline-flex;
}

.list>.toolbar>.button>.dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  padding: 10px;
  right: 0;
  top: 3.2rem;
  flex-direction: column;
}

.list>.toolbar>.button:hover .dropdown-menu {
  display: flex;
  justify-content: space-around;
  height: 12rem;
  width: 20rem;
}

.catalog-options {
  cursor: pointer;
}

.catalog-options:hover {
  background-color: var(--main-color-grey);
  border-radius: 5px;
  padding: 2px;
}

.opt-catalog-list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 1rem;
  margin-right: 1rem;
  width: 20px;
  height: 4.5rem;
  align-items: center;
  z-index: 50;
}

/* .list>.pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.list>.pagination>.select-per-page {
    margin: 1rem 0;
}

.list>.pagination>.select-per-page label {
    padding-right: 0.5rem;
}

.list>.pagination>.nb-page {
    margin: 1rem 0;
}

.list>.pagination>.nb-page button {
    width: 45px;
    height: 34px;
    margin: 0.2rem;
    background: white;
    border: var(--main-color-blue) 1px solid;
    border-radius: 5px;
    cursor: pointer;
}

.list>.pagination>.nb-page button:hover {
    background: var(--main-color-orange);
    color: white;
    border: var(--main-color-orange) 1px solid;
    border-radius: 5px;
}

.list>.pagination>.nb-page button:hover svg path {
    fill: white;
}

.list>.pagination>.nb-page>.active {
    background: var(--main-color-blue);
    color: white;
    border-radius: 5px;
}

.list>.pagination>.break-point {
    color: var(--main-color-orange);
} */

.pagination {
  width: 100%;
  margin: 1rem 0;
  padding-bottom: 1rem;
  list-style-type: none;
  text-align: center;
}

.pagination nav ul {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.page-item {
  padding: 0.5rem;
  border: 2px var(--main-color-blue) solid;
  border-radius: 5px;
  cursor: pointer;
}

/* POP-UP */

/* .tablePop {
    position: fixed;
    z-index: 9999;
    overflow: hidden;
    width: 85%;
    top: 50%;
    left: 57%;
    transform: translate(-50%, -50%);
    background: var(--main-color-blue);
    border: 1px solid #F0EFF4;
    padding: 2rem;
    border-radius: 5px;
} */
.tablePop {
  width: calc(90% - 250px);
  position: fixed;
  z-index: 9999;
  overflow: hidden;
  top: 55%;
  left: calc(50% + 125px);
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #f0eff4;
  padding: 15px;
  border-radius: 5px;
}

@media screen and (max-width:1024px) {
  .tablePop {
    width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

.titlePop {
  color: var(--main-color-blue);
  margin-bottom: 1rem;
}

.pop-up {
  position: fixed;
  z-index: 9999;
  overflow: hidden;
  width: 85%;
  top: 50%;
  left: 57%;
  transform: translate(-50%, -50%);
  background: var(--main-color-blue);
  border: 1px solid #f0eff4;
  padding: 2rem;
  border-radius: 5px;
}

.TablePop-up {
  display: flex;
  width: fit-content;
}

.line {
  width: 100%;
  border: 1px solid var(--main-color-grey);
  margin-top: 10px;
  border-radius: 1rem;
}

.pop-upTableTitle {
  color: var(--main-color-orange);
  /* text-align: center; */
}

.pop-upProspectList {
  margin: 1rem;
}

.pop-upText {
  color: white;
  /* font-size: 1.5rem; */
  font-family: "Wigrum-Bold";
  width: max-content;
}

.pop-up-new-cata {
  display: flex;
  flex-direction: column;
}

.pop-up-line {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  /* margin: 0.5rem; */
  color: white;
}

.pop-up-line label {
  color: white;
}

.pop-up-new-cata-validation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pop-up-input {
  margin-top: 3px;
  padding: 0.5rem;
  border: none;
  border-radius: 3px;
  color: black;
}

.pop-up-valid {
  background-color: var(--main-color-orange);
  border-radius: 5px;
  padding: 0.5rem;
  border: none;
  cursor: pointer;
  text-align: center;
  color: white;
}

.pop-up-cancel {
  cursor: pointer;
  color: var(--main-color-red);
}

.pop-up-new-item-line {
  color: white;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.pop-up-title {
  display: flex;
  justify-content: center;
}

.pop-up-submit {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pop-up-new-item-block-one {
  width: 50%;
}

.pop-up-new-item-block-two {
  width: 40%;
}

.pop-up-catalog {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pop-up-catalog select {
  margin-left: 1rem;
  border-radius: 5px;
}

.borderForm {
  margin-bottom: 1rem;
  /* border: 1px solid #cdcdcd; */
  border-radius: 10px;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
  padding: 24px;
  background-color: var(--main-color-grey);
}

.table {
  margin-bottom: 5rem;
}

table {
  width: 100%;
  max-width: 100%;
  background-color: var(--main-color-grey);
  border-radius: 10px;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
  border-collapse: collapse;
  line-height: 2;
}

.tdEdit {
  text-align: center;
}

.tdPrint {
  text-align: center;
}

.tdPrint path {
  fill: var(--main-color-blue);
}

tbody {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

th {
  font-weight: 600;
  padding: 0.7rem;
  font-size: 1rem;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  text-align: center;
  padding: 1rem;
  vertical-align: top;
  border-top: 0;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pop-up-new-item-line select {
  border-radius: 5px;
  width: 140px;
  padding: 0.2rem;
}

.pop-up-new-item-line input {
  border-radius: 5px;
  width: 170px;
  padding: 0.2rem;
}

.pop-up-new-item-line p {
  width: 120px;
  color: white;
}

.pop-up-area {
  display: flex;
  flex-direction: column;
}

.pop-up-area textarea {
  width: 950px;
  height: 150px;
  border-radius: 5px;
  padding: 0.5rem;
}

.pop-up-validation {
  width: 100%;
  height: auto;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendarBlur {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(156, 156, 156, 0.801);
  top: 1px;
  z-index: 100;
}

.calendarLogo {
  height: 1rem;
}

/* .calendarLogo:hover {
  filter: brightness(1) invert(1);
} */

.calendarLogoBg {
  display: flex;
  align-items: center;
  background-color: var(--main-color-grey);
  padding: 8px 10px;
  border-radius: 10px;
  text-decoration: none;
  color: var(--main-color-blue);
  font-size: 14px;
  font-family: "Wigrum-Medium";
  transition: 0.3s;
  margin-right: 8px;
  cursor: pointer;
  width: fit-content;
}

.calendarLogoBg:hover {
  background-color: var(--main-color-blue);
  color: white;
}

.calendarLogoBg:hover .calendarLogo {
  filter: brightness(1) invert(1);
}

/* .enventPointer {
  pointer-events: none;
} */

.depositCalendar {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/********** ADMIN **********/

.form-new-user {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1330px) {
  .main {
    padding-top: 0 !important;
  }

  .contract>.block {
    margin-top: 0 !important;
  }

  .navigation-sidebar {
    display: block;
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
    width: 250px;
    z-index: 100;
  }

  .menu-navigation {
    display: none;
  }

  .spacer {
    width: 0;
    height: 0;
  }

  .navigation-sidebar>.back-to-home {
    display: none;
  }

  .navigation-sidebar>.back-to-home-desktop {
    display: flex;
    height: 12%;
    cursor: pointer;
    background-color: #05162f;
    border-bottom: 1px solid #dddddd25;
    align-items: center;
    justify-content: center;
  }

  .navigation-sidebar>.back-to-home-desktop img {
    width: 180px;
    height: 50px;
  }

  .navigation-sidebar>.logout {
    display: none;
  }

  .navigation-sidebar>.menu>nav>ul>li a {
    border-bottom: 1px solid #dddddd25;
  }

  .add-button {
    display: none;
  }

  .navigation-sidebar>.promo p {
    padding-left: 0;
    padding-top: 10px;
  }

  /* POP-UP RESPONSIVE 1024px */

  .pop-up {
    width: 70%;
    padding: 1rem;
    position: fixed;
    left: 62%;
  }

  /* CONTACT RESPONSIVE 1024px */

  .allContactContent {
    /* border: red 1px solid; */
    display: grid;
    grid-template-columns: 70% 25%;
    gap: 5rem;
  }

  .type {
    width: 88%;
    height: 60px;
    margin: 0 6%;
    border-radius: 10px;
  }

  .filter {
    flex-direction: row;
    margin: 0px 6%;
  }

  .filter>.filter-component {
    flex-direction: row;
  }

  .filter>.filter-component div {
    display: flex;
    align-items: center;
  }

  .filter>.filter-component div select {
    width: auto;
    cursor: pointer;
  }

  .list {
    position: relative;
  }

  .list>.toolbar {
    margin: 15px 6%;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    width: 88%;
    height: 50px;
    border-bottom: 3px solid var(--main-color-orange);
    top: 0;
  }

  .list>.toolbar>.title {
    padding-left: 0px;
  }

  .list>.toolbar>.title h2 {
    font-size: 25px;
    color: var(--main-color-blue);
  }

  .list>.toolbar>.button {
    padding-right: 0px;
  }

  .list>.toolbar>.button a {
    font-size: 15px;
    background-color: var(--main-color-orange);
    padding: 10px;
    border-radius: 6px;
  }

  .list>.toolbar>.button a svg {
    margin-right: 5px;
  }

  .cards-list>.card:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px;
  }

  .xmark {
    right: -60px;
    height: 2rem;
  }

  .card>.ellipsis>.ellipsis-button,
  .card>.ellipsis>.ellipsis-xmark {
    right: 7%;
  }

  .card>.ellipsis>.col1 {
    right: 70%;
  }

  .card>.ellipsis>.col2 {
    right: 38%;
  }

  .card>.ellipsis>.col3 {
    right: 6%;
  }

  .card>.ellipsis>.option {
    right: 9%;
  }

  .card>.ellipsis>.opt1 {
    right: 73%;
  }

  .card>.ellipsis>.opt2 {
    right: 41%;
  }

  .card>.ellipsis>.opt3 {
    right: 9%;
  }

  .card-filter {
    width: 88%;
    height: fit-content;
    padding-bottom: 2rem;
    top: 12.5rem;
  }

  .row-one,
  .row-two {
    width: 90%;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 1rem;
  }

  .card-filter input {
    width: 9rem;
    margin-left: 1rem;
    margin-top: 0;
  }

  .card-filter select {
    width: 9rem;
    margin-left: 1rem;
  }

  #filter-submit {
    width: 9rem;
    margin-top: 0;
    margin-left: 1rem;
  }

  /**** DEVIS ******/
  .contract {
    width: 100%;
  }

  .contract>.toolbar {
    top: 0;
    /* margin: 20px -10px; */
    margin: 0px -10px 20px -10px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .contract>.toolbar>.back-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contract>.toolbar>.back-icon a {
    background-color: var(--main-color-grey);
    color: var(--main-color-blue);
    padding: 15px;
    border-radius: 8px;
  }

  .contract>.toolbar>.back-icon a:hover {
    background-color: var(--main-color-blue);
    color: white;
    padding: 15px;
    border-radius: 8px;
  }

  .contract>.toolbar>.back-icon a:hover img {
    filter: brightness(0) invert(1);
  }

  .contract>.toolbar>.header {
    padding-left: 15px;
  }

  .contract>.toolbar>.header>.ref h2 {
    width: fit-content;
    display: inline;
  }

  .contract>.toolbar>.tools a p {
    display: block;
    margin-right: 6px;
    font-size: 14px;
  }

  .contract>.toolbar>.tools button p {
    display: block;
    margin-right: 6px;
    font-size: 14px;
  }

  .contract>.toolbar>.tools>a {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    padding: 12px 8px;
    background-color: var(--main-color-orange);
    border-radius: 10px;
    color: white;
    margin: 0px 2px;
  }

  .contract>.toolbar>.tools>button {
    display: inline-flex;
    align-items: center;
    padding: 12px 8px;
    background-color: var(--main-color-orange);
    border-radius: 10px;
    color: white;
    margin: 0px 2px;
  }

  .contract>.toolbar>.tools a.garbage {
    padding: 12px 12px;
  }

  .contract>.toolbar>.tools>.dropdown a {
    border-radius: 8px;
    display: flex;
    align-items: center;
  }

  .contract>.toolbar>.tools>.dropdown a.dropdown-toggle p {
    display: none;
  }

  .contract>.block>.contract-body>.container>.group>.group-header>div:first-child>input {
    width: 200px;
  }

  .contract>.block>.contract-body>.container>.group>.line {
    flex-direction: row;
  }

  .contract>.block>.contract-body>.container>.group>.line>.line-component1 {
    width: 50%;
    margin: 0px 5px;
  }

  .contract>.block>.contract-body>.container>.group>.line>.line-component2 {
    width: 50%;
    font-size: 12px;
    margin: 0px 5px;
  }

  .contract>.block>.contract-body>.container>.group>.line textarea {
    height: 100%;
  }

  .contract>.block>.contract-body>.container>.price {
    position: absolute;
    top: 0;
    right: 0;
    width: 320px;
  }

  .contract>.block>.infos>.infos-block div {
    box-sizing: border-box;
    width: 100%;
    padding: 0px 10px;
  }

  .contact {
    grid-column: 1;
  }

  .contract>.block>.contract-body>.container>.margin {
    padding: 20px;
  }

  .contract>.block>.contract-body>.container>.margin>.margin-amounts {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-end;
  }

  .contract>.block>.contract-body>.container>.margin>.margin-amounts div {
    width: 50%;
    padding: 0px 10px;
    box-sizing: border-box;
  }

  .contract>.block>.contract-body>.container>.margin>.footer-margin {
    display: flex;
  }

  .contract>.block>.contract-body>.container>.margin>.footer-margin div {
    padding: 0px 10px;
  }

  /****** TABLEAU DE BORD ********/

  .dashboard-content {
    margin: 0px 6%;
    padding: 0px;
  }

  .dashboard-content div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 300px;
    border-radius: 8px;
    margin: 0;
  }

  .dashboard-content .welcome-card {
    background-color: var(--main-color-orange);
    width: 30%;
    height: 95%;
  }

  .dashboard-content .chart {
    height: 95%;
    background-color: #dddddd;
  }

  .dashboard-content .chart1 {
    /* background-color: blue; */
    width: 60%;
  }

  .dashboard-content .chart2 {
    /* background-color: red; */
    width: 45%;
  }

  .dashboard-content .chart3 {
    /* background-color: green; */
    width: 45%;
  }

  /***** MARGE *****/
  .contract>.block>.contract-body>.container>.margin-component {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  .contract>.block>.contract-body>.container>.margin-component>div {
    margin: 1%;
    min-width: 150px;
  }

  .contract>.block>.contract-body>.container>.margin-component>.margin-amounts>div {
    display: flex;
    align-items: center;
  }

  .contract>.block>.contract-body>.container>.margin-component>.margin-amounts p {
    font-size: 14px;
    text-align: right;
    margin-right: 15px;
  }

  .contract>.block>.contract-body>.container>.margin-component>.margin-amounts input {
    font-size: 14px;
    text-align: right;
  }

  .contract>.block>.contract-body>.container>.margin-component>.margin-results>div {
    display: flex;
    flex-direction: column;
    margin: 25px 0px;
  }

  /***** PARAMETRES *******/
  .list>.content {
    width: 88%;
    margin: 0px 6%;
  }

  .list>.content>.primary-settings input {
    border: none;
  }

  .list>.content>.primary-settings select {
    border: none;
  }

  .list>.content>.primary-settings>.fields {
    display: flex;
    border-radius: 10px;
    background-color: var(--main-color-grey);
  }

  .list>.content>.primary-settings>.fields>div {
    width: 50%;
    margin: 0px 15px;
  }

  .list>.content>.primary-settings>.fields .field {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .list>.content>.primary-settings>.fields .field p {
    width: 300px;
  }

  .list>.content>.primary-settings>.fields .field.siret-ape>div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .list>.content>.primary-settings>.fields .field.siret-ape>.siret input {
    margin-left: 55px;
  }

  .list>.content>.primary-settings>.fields .field.siret-ape>.ape p {
    width: 100px;
  }

  .list>.content>.primary-settings>.fields .field>.cp-ville {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .list>.content>.primary-settings>.interlocutors {
    border-radius: 10px;
    background-color: var(--main-color-grey);
  }

  .list>.content>.primary-settings>.interlocutors>hr {
    display: none;
  }

  .list>.content>.primary-settings>.interlocutors>.interlocutor {
    flex-direction: row;
  }

  .list>.content>.primary-settings>.interlocutors>.interlocutor input {
    font-size: 14px;
  }

  .list>.content>.primary-settings>.interlocutors>.interlocutor select {
    font-size: 14px;
  }

  .list>.content>.primary-settings>.interlocutors>.interlocutor>div {
    padding: 0px 5px;
  }

  .list>.content>.primary-settings>.interlocutors>.interlocutor>.civility {
    width: 8%;
  }

  .list>.content>.primary-settings>.interlocutors>.interlocutor>.last-name {
    width: 19%;
  }

  .list>.content>.primary-settings>.interlocutors>.interlocutor>.first-name {
    width: 19%;
  }

  .list>.content>.primary-settings>.interlocutors>.interlocutor>.function {
    width: 19%;
  }

  .list>.content>.primary-settings>.interlocutors>.interlocutor>.mail {
    width: 20%;
  }

  .list>.content>.primary-settings>.interlocutors>.interlocutor>.initials {
    width: 10%;
  }

  .list>.content>.primary-settings>.interlocutors>.interlocutor>.button {
    width: 5%;
    justify-content: flex-end;
  }

  .list>.content>.primary-settings>.interlocutors>.add a {
    background-color: white;
    color: var(--main-color-blue);
    width: auto;
    display: block;
    float: right;
    height: 35px;
  }

  .list>.content>.primary-settings>.interlocutors>.add a>svg {
    margin-right: 5px;
  }

  /****** AUTHENTIFICATION *******/
  .main>.sign-in-container {
    width: 70%;
    background-color: var(--main-color-grey);
    margin: 5% 15% 0px 15%;
    border-radius: var(--main-border-radius);
  }

  .main>.sign-in-container>.sign-in-block {
    flex-direction: row;
  }

  .main>.sign-in-container>.sign-in-block>div {
    padding: 15px;
    width: 50%;
  }

  .main>.sign-in-container>.sign-in-block>.authentification {
    width: 50%;
    align-items: flex-start;
  }

  .main>.sign-in-container>.sign-in-block>.text {
    width: 50%;
    align-items: flex-end;
    justify-content: center;
  }

  .main>.sign-in-container>.sign-in-block>.authentification>div {
    width: 100%;
  }

  .main>.sign-in-container>.sign-in-block>.authentification>div input {
    background-color: white;
    border: none;
  }

  .main>.sign-in-container>.sign-in-block>.text h2 {
    text-align: flex-end;
  }

  .main>.sign-in-container>.sign-in-block>.text h3 {
    text-align: flex-end;
  }

  .main>.sign-in-container>.sign-in-promo {
    display: flex;
    padding: 10px;
    height: 100px;
    background-color: var(--main-color-blue);
    justify-content: space-between;
    width: 100%;
    border-radius: var(--main-border-radius);
  }

  .main>.sign-in-container>.sign-in-promo>div {
    display: flex;
    align-items: center;
  }

  .main>.sign-in-container>.sign-in-promo>div p {
    color: #cbccd0;
    font-size: 12px;
    margin-right: 10px;
    font-family: "Wigrum-Light";
    font-weight: 200;
  }

  .main>.sign-in-container>.sign-in-promo>div img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 50px;
    width: auto;
  }

  /****** CONTACT ******/
  .list>.content>.historic-quote {
    margin: 0px;
  }

  /****** ACOMPTE ******/

  .contract>.block>.contract-body>.container>.deposit>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 10px 0px;
    justify-content: center;
  }

  .contract>.block>.contract-body>.container>.deposit>div label {
    width: 250px;
    text-align: flex-end;
    margin-right: 10px;
  }

  .contract>.block>.contract-body>.container>.deposit>div input {
    width: 150px;
    border-radius: var(--main-border-radius);
    padding: 5px;
    font-size: 18px;
    font-family: "Wigrum-Bold";
    margin-right: 5px;
  }

  /******* ITEMS LISTE ********/

  .cards-list.items {
    width: 92%;
    margin: 0px 6%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
  }

  .cards-list>.card.item {
    width: 31%;
    margin: 4px 4px;
  }

  .list>.search-bar a {
    width: 88%;
    margin: 20px 6%;
  }

  .list>.content>.primary-settings>.fields .field.designation textarea {
    border: none;
  }

  /******* DASHBOARD ADMIN *****/

  .dashboard-admin-content {
    flex-direction: row;
    width: 85%;
    margin: 0px 7.5%;
  }

  .dashboard-admin-content>div {
    margin: 10px;
  }

  .dashboard-admin-content>div>.title svg {
    margin-right: 10px;
  }

  .dashboard-admin-content>div h2 {
    font-size: 18px;
  }

  .loader {
    top: 50%;
    left: 45%;
    transform: translate(-50%, -55%);
  }
}

@media screen and (max-width: 770px) {

  .th3,
  .th4 {
    display: none;
  }

  .td3,
  .td4 {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  .contract>.toolbar {
    flex-direction: row;
    width: 100%;
  }

  .contract>.toolbar>.tools {
    margin-bottom: 0;
    justify-content: flex-end;
    width: auto;
  }
}

@media screen and (min-width: 770px) {
  .list>.toolbar>.button>a>span {
    margin-left: 10px;
    display: block;
  }

  .cardName {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .cards-list>.card>.tileCard div {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .functionCard {
    font-size: 1.2rem;
  }

  .letterFilter {
    font-size: 1rem;
  }
}

@media screen and (min-width: 770px) and (max-width: 1024px) {
  .main {
    margin-left: 0;
  }

  .contract>.block {
    position: relative;
  }

  /* POP-UP RESPONSIVE 770px */

  .pop-up {
    width: 95%;
    position: fixed;
    left: 50%;
  }

  .tablePop {
    width: 95%;
    position: fixed;
    left: 50%;
  }

  .contract>.block>.contract-body>.container>.price {
    position: absolute;
    top: 0;
    right: 0;
    width: 220px;
    gap: 8px;
  }

  .card-filter {
    width: 95%;
    top: 17rem;
  }
}

@media screen and (min-width: 1535px) {
  .searchFilter {
    .title {
      .titleSpan {
        .loopIcon {
          display: block;
        }
      }
    }

    .searchFilterForm {
      display: none;

      .inputContent {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        .input {
          margin-top: 0;
          margin-right: 1rem;
        }
      }
    }

    .active {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (min-width: 1025px) {
  .contract>.toolbar>.header>.ref>.date {
    margin-top: 0;
  }

  .searchFilter {
    .title {
      margin: 0;

      .titleSpan {
        .loopIcon {
          display: block;
        }
      }
    }

    .searchFilterForm {
      display: none;

      .inputContent {
        .input {
          margin-right: 1rem;
        }
      }
    }

    .active {
      display: flex;
    }

    .searchFilterActions {
      justify-content: flex-end;

      .searchSubmitButton {
        margin-left: 1rem;
      }
    }
  }

  .loginContent {
    width: 90%;
  }

  .contract>.toolbar>.header>.ref {
    flex-direction: row;
  }

  .reloadFilter {
    margin-left: 1rem !important;
  }

  .contract>.toolbar>.header>.ref>.num {
    margin-right: 10px;
  }

  .contract>.toolbar>.header>.ref input {
    padding: 5px;
    width: 150px;
  }

  .contract>.block>.infos>.infos-block div {
    padding: 0px 5px;
  }
}

@media screen and (min-width: 1920px) {
  .searchFilter {
    .title {
      .titleSpan {
        .loopIcon {
          display: block;
        }
      }
    }

    .searchFilterForm {

      .inputContent {
        .select {
          margin: 0;
        }
      }
    }
  }

  .pop-up {
    width: 35%;
    position: fixed;
    left: 57%;
  }

  .letterFilterGroup {
    overflow: visible;
  }

  .tablePop {
    width: 70%;
    position: fixed;
    left: 57%;
  }

  .card {
    justify-content: normal;
  }
}

@media screen and (min-width: 1400px) {
  .loginContent {
    width: 65%;
  }
}

@media screen and (min-width: 400px) {
  .allCheck {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    gap: 1rem;
  }


  .typeCard {
    margin: 1rem;
    padding: 5px;
    background-color: var(--main-color-blue);
    color: white;
    display: flex;
    height: fit-content;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: small;
  }

  .checkBox {
    cursor: pointer;
    display: flex;
  }
}

.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

/*PAGINATION*/
.pagination>button {
  background-color: #ffffff;
  border: 1px solid #1f3449;
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  margin: 5px;
}

.pagination>button:hover {
  background-color: #1f3449;
  color: #ffffff;
  cursor: pointer;
}

.pagination>.disabled:hover {
  background-color: #ffffff;
  fill: #bbbbbb;
  cursor: not-allowed;
}

.disabled {
  border: 1px solid #bbbbbb !important;
  color: #bbbbbb !important;
}

.disabled>svg>path {
  fill: #bbbbbb;
}

.disabled:hover>svg>path {
  fill: #bbbbbb;
}

.pagination button:hover>svg>path {
  color: #ffffff;
}

.pagination>button.active {
  background-color: #1f3449;
  color: #ffffff;
}

/*TOTALAMONTS*/

.totalamonts>table {
  width: 100%;
  border-radius: 10px;
  padding: 20px;
}

.totalamonts td {
  text-align: center;
  padding: 0.5rem;
  border: none;
}

/*MAILPOP*/
.mailpop {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: calc(50% - 200px);
  background-color: #ffffff;
  border-radius: 0.5rem;
  z-index: 100;
  padding: 1rem;
  box-shadow: 0 0 0.5rem #1f3449;
  width: 40%;
}

.mailpopHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem;
}

.mailpopHeader a {
  text-decoration: none;
  font-weight: bold;
  padding: 0 0.5em;
}

.mailpopHeader a:hover {
  background-color: #1f3449;
  color: #ffffff;
  border-radius: 50%;
}

.mailpopContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  margin: 0.5rem;
  width: 80%;
}

@media screen and (max-width: 600px) {
  .mailpopContainer {
    grid-template-columns: 1fr;
    gap: 10px;
  }

}

.mailpopContainer input {
  margin-bottom: 0.5rem;
  width: 50%;
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: solid 1px #1f3449;
}

@media screen and (max-width: 900px) {
  .mailpopContainer input {
    width: 100%
  }
}

.mailpopContainer textarea {
  resize: none;
  margin-bottom: 0.5rem;
}

.mailpopContainer button {
  width: 10rem;
  border-radius: 0.3rem;
  border: none;
  background-color: #fdab0d;
  color: #ffffff;
  padding: 5px;
}

.mailpopContainer button:hover {
  background-color: #e68600;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .mailpop {
    top: 25%;
    left: 0;
    width: 70%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1536px) {
  .cardOptionsPop {
    right: 10%;
  }
}

@media screen and (min-width: 1200px) {
  .closeCardOption {
    display: none !important;
  }

  .contract>.toolbar>.tools>.dropdown a.dropdown-toggle p {
    display: block;
  }
}

@media screen and (max-width: 1536px) {
  .cardOptionsPop {
    right: 7%;
  }
}

.toast-container {
  left: calc(50% + 125px);
  z-index: 99999;
}

@media screen and (max-width: 1024px) {
  .toast-container {
    left: 50% !important;
  }
}

@media screen and (max-width: 480px) {
  .toast-container {
    left: 0 !important;
  }

}

.graphContent__grid_left_pie svg>g {
  transform: translate(25%);
}