.loginHeader
  display: flex
  justify-content: space-between
  align-items: center
  height: 5rem
  background-color: $mainBlue
  &__logoContainer
    height: 100%
    display: flex
    margin: 1rem
    align-items: center
    &_logo
      cursor: pointer
      margin-right: 2rem
      height: 70%
      @media screen and (max-width: 400px)
        margin-right: 0

  &__rightContainer
    margin: 1rem
    display: flex
    align-items: center
    height: 100%
    &_registerButton
    &_iconContainer
      background-color: $mainOrange
      border-radius: 50%
      height: 42px
      width: 42px
      display: flex
      justify-content: center
      align-items: center
      &_icon
        cursor: pointer
        height: 15px
        width: 21px
        padding: 10px
        overflow: visible
        box-sizing: content-box
        fill: none
  &__line
    height: 100%
    border: none
    background-color: #475767
    width: 2px
    margin: 0 15px
    @media screen and (max-width: 400px)
      display: none
