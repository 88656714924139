.linkedDepositList
  display: flex
  flex-direction: column
  margin: 15px 0px
  &__header
    margin: 5px 0px
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    @media (max-width: 1440px)
      flex-direction: column
      gap: 10px
      align-items: flex-start

    p
      font-size: 120%
    &_amount
      display: flex
      align-items: center
  &__article
    display: flex
    flex-direction: column
    margin: 5px 0px
    &_item
      height: 47px
      display: flex
      align-items: center
      flex-direction: row
      justify-content: space-between
      margin: 5px 0px
      cursor: pointer
      text-decoration: none
      background-color: #fac15a
      color: white
      border-radius: 6px
      padding-left: 10px
      section
        display: flex
        align-items: center
        height: 100%
        div
          display: flex
          flex-direction: row
        div.v-line
          border-left: 1.5px dashed $white
          height: 30px
          margin: 0px 10px

        p
          display: flex
          padding: 0 5px 0 10px
          height: 100%
          align-items: center
          border-radius: 20px 6px 6px 20px / 28px 6px 6px 28px
          border-left: solid 0 white
          background-color: var(--main-color-orange)
          transition: 0.5s
          margin-left: 10px
          path
            color: white
          &:hover
            padding: 0 15px 0 20px
            border-radius: 20px 6px 6px 20px / 28px 6px 6px 28px
            transition: 0.3s
            path
              color: red
              transition: 0.3s
    span
      margin: 5px 0px
      font-family: "Wigrum-Bold"
      color: white

.addItem
  background-color: var(--main-color-orange)
  justify-content: center
  overflow: hidden
  position: relative

  .addText
    opacity: 0
    white-space: nowrap
    transform: translateX(10px)
    position: relative
    left: 50px
    color: white
    transition: opacity 0.4s ease-out, transform 0.4s ease-out

  .addPlus
    transition: transform 0.4s ease-out
    position: absolute
    path
      color: white

  &:hover .addText
    opacity: 1
    transform: translateX(-30%)
    transition-delay: 0.2s

  &:hover .addPlus
    transform: translateX(-100px) rotate(180deg)

.v-line-blue
  border-left: 1.9px dashed $mainBlue
  height: 30px
  margin: 0px 10px
