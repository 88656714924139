.navigationHeader
    background-color: $mainBlue
    user-select: none
    @media screen and (min-width: 1024px)
        display: none
    &__container
        +flexBeetweenCenter
        height: 4rem
        padding: 0 5px
        &_logo
            height: 80%
            cursor: pointer
        &_icon
            border-radius: $mainBorderRadius
            background-color: $mainOrange
            padding: 10px
            font-size: 2rem
            cursor: pointer
            &:hover
                background-color: darken($mainOrange, 10%)
            path
                color: white
