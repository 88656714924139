.container
    &__component
        width: 100%
        background-color: var(--main-color-grey)
        border-radius: 10px
        display: flex
        height: 100%
        padding: 20px
        flex-direction: row
        margin-bottom: 25px
        @media screen and (max-width: 650px)
            flex-direction: column
            padding: 10px

        div
            padding: 10px
        &_amounts
            display: flex
            flex-wrap: wrap
            min-width: 60%
            align-items: flex-end
            flex-direction: column
            margin-left: 0
            p
                width: 60%
                text-align: right
                @media screen and (max-width: 650px)
                    text-align: left

            div
                width: 100%
                align-items: center
                margin: 8px 0px 0px 0px
                padding: 0px 10px
                box-sizing: border-box
                display: flex
                align-items: center
                input
                    border: none
                    border-radius: 6px
                    margin-left: 10px
                    height: 40px
                    font-size: 18px
                    padding-right: 8px
                    font-size: 14px
                    text-align: right
                    width: auto
        &_results
            div
                margin: 8px 0px
                display: flex
                flex-direction: column
                margin: 25px 0px
            p
                margin-bottom: 15px
            h3
                color: $mainOrange
                font-size: 22px
                margin: 0px
