.admin-connections
    margin: 2rem 0
    &__table
        &_head
            position: sticky
            top: 0
            z-index: 10
            background-color: #f1f1f1
            th
                white-space: normal
        &_body
            &_row
                border-bottom: 1px solid #ddd
                &:nth-child(odd)
                    background-color: #f9f9f9
                &:nth-child(even)
                    background-color: #ffffff
                &:hover
                    background-color: #f1f1f1
                td
                    font-size: 0.8rem
                    padding: 0 3px
                    white-space: normal
                    line-height: 2
                &:hover
                    cursor: pointer
