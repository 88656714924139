.contactInfosPanel
  display: flex
  gap: 2rem
  width: 100%
  @media screen and (max-width: 1024px)
    flex-direction: column
    gap: 1rem

  &__content
    width: 100%
    @media screen and (max-width: 1024px)
      width: 100% !important

    &_title
      +sectionSubtitle
    &_name
      padding: 1rem
    &_line
      border: none
      height: 1px
      border-radius: $mainBorderRadius
      background-color: $lineColor
  &__button
    padding: 0 2rem
    display: flex
    justify-content: space-between
    align-items: center
    font-size: 14px
    text-transform: uppercase
    font-weight: bold
    background-color: $mainGray
    border: none
    border-radius: $mainBorderRadius
    width: 95%
    cursor: pointer
    @media screen and (max-width: 1024px)
      height: 70px
      width: 100%

    &_icon
      font-size: 24px
