.stateFilter
    width: 100%
    height: 50px
    background-color: $mainGray
    display: flex
    overflow-y: hidden
    -ms-overflow-style: none
    @media screen and (max-width: 1024px)
        overflow: auto
    &__title
        text-transform: uppercase
        display: flex
        min-width: 5rem
        justify-content: center
        align-items: center
        cursor: pointer
        position: relative
        text-align: center
        margin: 0px 15px
        font-size: 100%
        white-space: nowrap
        font-family: $mainFontLight
        flex-shrink: 0
        @media screen and (max-width: 1250px)
            font-size: 12px

        @media screen and (max-width: 1024px)
            font-size: 12px
            margin: 0 10px
        &::before
            content: ""
            position: absolute
            left: 50%
            transform: translate(-50%, 0)
            bottom: 0
            height: 1px
            width: 70%
            border-bottom: 3px solid $stateLineGray
        &.active
            font-family: $mainFontMedium
            &::before
                border-bottom: 3px solid $mainOrange
