.loginFooter
  border-radius: $mainBorderRadius
  background-color: $mainBlue
  padding: 2rem
  height: 7rem
  display: flex
  align-items: center
  justify-content: space-between
  @media screen and (max-width: 900px)
    flex-direction: column
    height: auto
    padding: 1rem
    gap: 1rem
    &_leftContainer
      gap: 1rem
      height: auto
      &_logo
        width: 126px
    &_rightContainer
      gap: 1rem
      &_txt
        font-size: 10px
        color: $mainGray
      &_button
        background-color: rgba(254, 254, 254, 0.2) !important
        margin-left: 2rem
        padding: 10px !important
        height: fit-content
        line-height: 12px
        svg
          margin-left: 10px !important
          font-size: 1rem
          path

  &__leftContainer
    display: flex
    align-items: center
    gap: 1rem
    height: 7rem
    @media screen and (max-width: 500px)
      flex-direction: column
    &_logo
      cursor: pointer
      width: 126px
  &__rightContainer
    justify-self: end
    &_txt
      color: #CBCCD0
      font-size: 10px
      text-align: center
    &_button
      height: fit-content
      padding: 10px !important
      background-color: rgba(254, 254, 254, 0.2) !important
      line-height: 12px
      width: fit-content !important
      svg
        font-size: 1rem
        margin-left: 10px !important
        path
