.adminDashboard
  &__container
    display: flex
    flex-direction: row
    flex-wrap: wrap
    gap: 20px
    &_tile
      background-color: var(--main-color-grey)
      padding: 1.5rem
      border-radius: 15px
      &_title
        padding: 0 0 10px 0
        margin: 10px 0
        border-bottom: 1px solid $mainBlue
        font-size: 1.2rem
      &_table
        &_row
          &_cell
            &:hover
              cursor: pointer
              text-decoration: underline
