@mixin mainOrangeLine()
    width: 100%
    background-color: $mainOrange
    border-radius: $mainBorderRadius
    height: 3px
    border: none

@mixin buttonParam($bgColor, $color, $hoverColor)
    background-color: #{$bgColor}
    color: #{$color}
    cursor: pointer
    &:hover
        background-color: darken($hoverColor, 10%)
        color: #{$color}


@mixin basicInputs()
    min-width: 8rem
    border: none
    border-radius: $mainBorderRadius
    padding: 15px

@mixin underLineInput()
    min-width: fit-content
    cursor: pointer
    border: none
    border-bottom: 1px solid #000
    &:hover
        border-bottom: 1px solid $mainOrange