.validPop
  position: fixed
  z-index: 10000
  top: 50%
  left: calc(50% + 125px)
  transform: translate(-50%, -50%)
  box-shadow: $mainBoxShadow
  display: flex
  flex-direction: column
  align-items: center
  background-color: #fff
  padding: 2rem
  border-radius: $mainBorderRadius
  @media screen and (max-width: 1024px)
    width: 70%
    left: 50%
    transform: translate(-50%, -50%)
  @media screen and (max-width: 400px)
    font-size: 90%

  &__title
    margin-bottom: 10px
  &__desc
  &__buttonContainer
    margin-top: 2rem
    gap: 1rem
    width: 100%
    display: flex
    justify-content: space-between
    &_backButton
      background-color: $mainBlue
      width: 30%
      @media screen and (max-width: 1024px)
        width: 45%
      @media screen and (max-width: 400px)
        font-size: 90%

      &:hover
        background-color: $mainBlueHover
    &_continueButton
      width: 30%
      @media screen and (max-width: 1024px)
        width: 45%
      @media screen and (max-width: 400px)
        font-size: 90%
