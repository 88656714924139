.invoiceLine
  margin: 1rem 0
  padding: 16px
  border-radius: $mainBorderRadius
  background-color: $mainGray
  display: flex
  gap: 24px
  height: 240px
  @media screen and (max-width: 1024px)
    flex-direction: column
    height: auto
    padding: 16px 8px
    gap: 12px

  &__leftContent
    display: flex
    flex-direction: column
    height: 100%
    width: 60%
    gap: 24px
    position: relative
    @media screen and (max-width: 1024px)
      width: 100%
      gap: 12px
    &_header
      display: flex
      justify-content: space-between
      align-items: center
      gap: 5px
      @media screen and (max-width: 1024px)
        flex-wrap: wrap
        gap: 15px
      &_leftPart
        height: 1rem
        display: flex
        gap: 10px
        align-items: center
        &_cart
          display: flex
          align-items: center
          background-color: white
          padding: 6px 8px
          gap: 10px
          border: none
          border-radius: 6px
          transition: 0.3s
          text-decoration: none
          p
          &:hover
            cursor: pointer
            background-color: $mainBlue
            p
              color: white
            img
              filter: brightness(0) invert(1)
        &_number
          background-color: #fff
          padding: 5px
          height: 28px
          width: 28px
          border-radius: 50%
          line-height: 1
          text-align: center
        &_article-button
          border: none
          padding: 10px
          background-color: white
          border-radius: $mainBorderRadius
          display: flex
          align-items: center
          justify-content: center
          gap: 7px
          text-align: center
          height: 28px
          line-height: 1
          &:hover
            cursor: pointer
            background-color: rgb(0,0,0,0.1)
          &.change-article-button
            background-color: $mainBlue
            color: white
      &_arrowContainer
        display: flex
        gap: 10px
        align-items: center
        height: 28px
        &_arrow
          cursor: pointer
          padding: 5px
          border-radius: 3px
          border: 1px solid $mainBlue
        &_deleteButton
          background-color: white
          border: none
          border-radius: $mainBorderRadius
          font-size: 100%
          cursor: pointer
          height: 28px
          img
            background-color: #fff
            padding: 6px
    &_textarea
      border: none
      width: 100%
      max-width: 100%
      height: 100%
    &_erasedContainer
      position: absolute
      bottom: 8px
      left: 12px
      &_button
        height: 28px
        background-color: $mainGray
        border: none
        border-radius: 5px
        padding: 4px 12px
        display: flex
        gap: 7px
        align-content: center
        align-items: center
        font-family: $mainFontMedium
        img
          width: 15px
        &:hover
          cursor: pointer
          background-color: rgb(0,0,0,0.1)

  &__rightContent
    display: flex
    flex-direction: column
    width: 40%
    @media screen and (max-width: 1024px)
      width: 100%

    &_inputContainer
      margin-bottom: 10px
      display: flex
      justify-content: space-between
      align-items: center
      gap: 1rem
      border-bottom: 1px solid $lineColor
      &_label
        min-width: max-content
      input
        padding-right: 23px
        &:disabled
          color: #808080
          cursor: not-allowed
      &_input
        padding: 5px
        border: none
        background-color: transparent
        width: 100%
        text-align: end
        &:disabled
          cursor: not-allowed
        option
          text-align: end
    &_totalContainer
      display: flex
      flex-direction: column
      justify-content: flex-end
      height: 100%
      gap: 6px
      &_total
        display: flex
        justify-content: flex-end
        font-weight: 700
        font-size: 18px
        &.line-discount-total
          font-family: $mainFontLight
          font-weight: 400
          color: $mainOrange
          font-size: 16px
          span
            font-family: $mainFontLight
            color: $mainOrange
