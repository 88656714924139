.margin-creator
  display: flex
  flex-direction: column
  gap: 32px
  margin: 25px 0px
  background-color: var(--main-color-orange)
  border-radius: 10px
  padding: 32px 16px 16px 16px
  *
    color: white
  p
    font-family: $mainFontLight
  &__header
    display: flex
    flex-direction: column
    gap: 16px
    &_description
      line-height: 21px
  &__amounts
    margin: 8px 0px
    // display: grid
    // grid-template-columns: 48% 48%
    display: flex
    flex-direction: column
    // grid-template-rows: repeat(3, 100px)
    justify-content: space-between
    div
      width: 100%
      padding: 8px 0px 8px
    p
      font-family: $mainFontMedium
    input
      color: black
      border: none
      outline: 0
      border-radius: 6px
      height: 50px
      width: 100%
      padding-left: 10px
      margin-top: 8px
  &__footer
    display: flex
    flex-direction: column
    gap: 32px
    &_data
      display: flex
      flex-direction: column
      gap: 32px
      .v-line
        border-left: 1.5px dashed $white
        height: 50px
        margin: 0px 20px
      &_number
        font-size: 2rem
        .margin-creator__footer &
          font-family: $mainFontMedium
    &_button
      button
        width: 100%
        background-color: rgba(255, 255, 255, 0.20)
        border: none
        border-radius: 6px
        padding: 15px 14px
        text-transform: uppercase
        &:hover
          cursor: pointer
