.itemPop
    position: fixed
    z-index: 1000
    overflow: auto
    top: 50%
    left: calc(50% + 125px)
    transform: translate(-50%, -50%)
    background: white
    border: 1px solid #f0eff4
    border-radius: 5px
    max-height: 90%
    width: 60%
    max-width: 900px
    @media only screen and (max-width: 1350px)
        width: 70%
    @media only screen and (max-width: 1024px)
        width: 80%
        left: 50%
    &__header
        margin-bottom: 0.5rem
        display: flex
        justify-content: space-between
        &_options
            display: flex
            align-items: center
            gap: 15px
            &_icon
                height: 2rem
        &_title
            font-size: 1.5rem
            font-weight: 600
            color: var(--main-color-black)
    &__name-input
        margin: 1rem 0
        width: 50%
        input
            width: 100% !important
    &__container
        margin: 2rem
        background-color: var(--main-color-grey)
        display: flex
        flex-direction: column
        flex-wrap: wrap
        padding: 2rem
        gap: 2rem
        justify-content: space-between
        border-radius: 5px
        width: calc(100% - 4rem)
        @media screen and (max-width: 450px)
            padding: 10px
            margin: 10px
            width: calc(100% - 20px)

        &_content
            display: flex
            width: 100%
            &_select-div
                display: flex
                flex-direction: row
                justify-content: space-between
                &_left
                    display: flex
                    justify-content: flex-start
                    @media screen and (max-width: 930px)
                        flex-direction: column

            .select
                gap: 10px !important
                @media screen and (max-width: 930px)
                    gap: 0px !important
                @media screen and (max-width: 700px)
                    flex-direction: column

                label
                    align-self: center
                    width: 108px
                    @media screen and (max-width: 930px)
                        align-self: flex-start
                option
                    text-overflow: ellipsis
                    overflow: hidden
                    max-width: 215px
                    display: block
        &_name-input
            width: 50%
            input
                padding: 1rem
                width: 100% !important
        &_select
            border: none
            border-radius: 5px
            padding: 15px
            margin: 10px 0px
            flex: 1
            max-width: 220px
            min-width: 220px
            @media screen and (max-width: 700px)
                max-width: 100%
                min-width: 100%

        &_second-block
            display: flex
            flex-direction: row
            justify-content: space-between
            width: 100%
            @media screen and (max-width: 930px)
                // flex-direction: column
                justify-content: center
                @media screen and (max-width: 600px)
                    flex-direction: column
            input
                @media screen and (max-width: 600px)
                    box-sizing: border-box
                    width: 100% !important
            &_col
                width: 45%
                display: flex
                flex-direction: column
                gap: 1rem
                @media screen and (max-width: 930px)
                    width: 100%
                &_input
                    display: flex
                    justify-content: space-between
                    align-items: center
                    @media screen and (max-width: 930px)
                        flex-direction: column
                        justify-content: center
                        align-items: center
                        gap: 10px

    &__footer
        display: flex
        justify-content: flex-end
        margin: 0 2rem 2rem 2rem
        &_button
