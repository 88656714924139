.searchFilter
  margin: 2rem 0
  border-radius: var(--main-border-radius)
  background-color: var(--main-color-grey)
  padding: 0 20px
  &.without-stateFilter
    margin-top: 0
  &__title
    text-transform: uppercase
    font-size: 1rem
    user-select: none
    margin-bottom: 0
    cursor: pointer
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    padding: 0.5rem 1.5rem 0.5rem 1rem
    height: 67px
    &_titleSpan
      display: flex
      align-items: center
      font-weight: 700
      font-size: 14px
      font-family: $mainFontMedium
      &_loopIcon
        font-size: 1.5rem
        margin-right: 1rem
        &_plusIcon
          height: 25px
          transition: all 0.3s ease
          // top: 27px
        .iconRotate
          transform: rotate(135deg)
          transition: all 0.3s ease
  &__searchFilterForm
    display: none
    flex-direction: column
    gap: 1rem
    height: 0
    opacity: 0
    transition: all 0.3s ease, opacity 0s ease
    &_inputContent
      display: flex
      flex-wrap: wrap
      gap: 1rem
      input
        height: 55px
        box-sizing: border-box !important
    &.active
      padding: 1rem 0 26px 0
      max-height: 100rem
      height: auto
      opacity: 1
      transition: all 0.3s ease
      display: flex
      flex-direction: column
      button
        display: flex !important
        width: auto !important

  &__searchFilterActions
    gap: 1rem
    display: flex
    align-items: center
    flex-wrap: wrap
    width: auto

    &_searchFilterReset
      cursor: pointer
      font-size: 2rem
      transform: scale(1)
      transition: transform 0.2s
      &:hover
        transform: scale(1.1)
        transition: transform 0.2s
        &:active
          transform: scale(0.9) rotate(360deg)
          transition: transform 0.2s
      &_button
        height: fit-content
        // display: none !important
        width: 120px
    &_eraser-block
      display: flex
      gap: 8px
      align-content: center
      &_eraser
        width: 20px
      &_text
        font-size: 15px
        font-family: $mainFontBold
          // line-height: normal
        font-weight: 700
        color: #666666
        text-decoration: underline
        text-underline-offset: 25%
        display: flex
        align-self: center
        &:hover
          cursor: pointer

.rs-picker-input-group
  height: 55px
  border: none !important
  outline: none !important
