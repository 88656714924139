.basicInputContainer
  min-height: 50px
  &__label
  &__basicPassInput
    position: relative
    &_icon
      user-select: none
      cursor: pointer
      right: 10px
      top: 50%
      transform: translateY(-50%)
      position: absolute
  &__button
    +basicInputs
    box-sizing: content-box
