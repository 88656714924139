.admin-customer
    display: block !important
    &__contact
        &_account
            margin: 2rem 0
            background-color: var(--main-color-grey)
            padding: 1.5rem
            border-radius: 15px
            display: grid
            grid-template-columns: 2fr 1fr
            gap: 30px
            @media screen and (max-width: 768px)
                grid-template-columns: 1fr
                &_right
                    display: flex
                    flex-wrap: wrap
                    gap: 20px
                    p
                        width: 50%
                    input
                        width: 40%
                        height: 30px

            &_left
                display: grid
                grid-template-columns: 1fr 2fr
                gap: 20px
                @media screen and (max-width: 768px)
                    grid-template-columns: 1fr

                &_inputs
                    display: flex
                    flex-direction: column
                    gap: 15px
                    label
                        display: flex
                        gap: 10px
                    &_email-sending
                        display: flex
                        flex-direction: column
                        gap: 20px
                        button
                            max-width: 300px
                            width: 100%
            &_right
                display: flex
                flex-wrap: wrap
                gap: 20px
                p
                    width: 50%
                input
                    width: 40%
                    height: 30px
        &_contact-closure
            display: flex
            justify-content: center
            margin-bottom: 2rem
            button
                @media screen and (max-width: 650px)
                    width: 100%
