.invoiceFormContainer
  &__groupsContainer
    &_line
      border: none
      height: 2px
      border-radius: $mainBorderRadius
      background-color: #E0E0E0
      margin: 32px 0
    &_addGroupButton
      height: 48px
      cursor: pointer
      color: $white
      display: flex
      justify-content: space-between
      align-items: center
      background-color: $mainBlue
      border-radius: $mainBorderRadius
      padding: 0.5rem 1rem
      width: 100%
      border: none
      &_icon
        background-color: $white
        border-radius: 50%
        padding: 5px
        height: 1rem
        width: 1rem
        path
          fill: $mainBlue
