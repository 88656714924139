.dashboard-container
  padding: 20px 40px
  width: 100%
  &__header
    &_head
      display: flex
      align-items: center
      justify-content: space-between
      padding: 10px 0
      &_content
        &_title
          padding: 0.5rem 0
      &_society-name
        font-family: $mainFontBold
  &__content
    display: flex
    gap: 30px
    margin-top: 1.5rem

.welcome-pop
  max-width: 387px
  @media (max-width: 1722px)
    max-width: 352px
  &__content
    position: relative
    padding: 50px 40px 40px 40px
    background-color: #FFB300
    border-radius: 10px
    height: 100%
    min-height: min-content
    min-width: 352px
    font-size: 12px
    line-height: 18px
    display: flex
    flex-direction: column
    *
      color: white
    &_title
      font-size: 22px
      font-family: $mainFontMedium
      text-transform: uppercase
      margin-bottom: 10px
      width: 80%
      line-height: 26px
    &_container
      display: flex
      flex-direction: column
      justify-content: space-around
      // height: 100%
      &_description
        margin: 20px 0
        font-family: $mainFontLight
        font-weight: 500
        font-size: 16px
        line-height: 18px
      &_important
        margin-bottom: 20px
        font-family: $mainFontMedium
        font-size: 16px
      &_button-container
        display: flex
        flex-direction: column
        justify-content: start
        align-items: flex-start
        gap: 15px
        button
          height: 48px
          display: flex
          justify-content: space-between
          align-items: center
          gap: 15px
          &:hover
            cursor: pointer
            transition: all 0.3s
        &_user-guide-button
          font-family: $mainFontLight
          font-weight: 300
          background-color: rgba(255, 255, 255, 0.20)
          border-radius: 8px
          padding: 10px 20px
          border: none
          &:hover
            background-color: darken(rgba(255, 255, 255, 0.20), 15%)
        &_settings-button
          font-family: $mainFontLight
          font-weight: 300
          background-color: #1F3449
          border-radius: 8px
          padding: 10px 20px
          border: none
          &:hover
            background-color: lighten(#263238, 10%)
    &_close-button_container
      display: flex
      justify-content: center
      align-items: center
      background-color: white
      position: absolute
      height: 30px
      width: 30px
      border-radius: 15px
      top: 10px
      right: 10px
      cursor: pointer
      //font-size: 8px
      svg
        color: black
        height: 16px
        width: 16px
        path
          color: black

.bar-chart
  width: 100%
  &__title
    font-family: $mainFontMedium
    font-size: 20px
    font-weight: 700
    line-height: 26px
  &__filter
    display: flex
    justify-content: space-between
    width: 100%
    margin: 30px 0
    gap: 30px
    @media screen and (max-width: 1024px)
      flex-direction: column
      gap: 20px

    &_left
      display: flex
      flex-direction: row
      justify-content: center
      // width: 50%
      gap: 30px
      @media screen and (max-width: 1024px)
        width: 100%
        gap: 0
        justify-content: space-between

      &_col
        display: flex
        flex-direction: column
        justify-content: space-between
        gap: 12px
        // width: 45%
        @media screen and (max-width: 1024px)
          width: 45%
          gap: 0

        &_title
          font-family: $mainFontMedium
          font-size: 14px
          font-weight: 500
          line-height: normal
        &_select-wrapper
          position: relative
          display: inline-block
          &_select
            padding: 10px 0
            border: none
            border-bottom: 1px solid $mainBlue
            appearance: none
            -webkit-appearance: none
            -moz-appearance: none
            width: 100%
            color: #666666
          &_select-arrow
            position: absolute
            top: 50%
            right: 10px
            transform: translateY(-50%)
            pointer-events: none
            color: #666666
    &_right
      display: flex
      flex-direction: row
      justify-content: center
      gap: 20px
      height: 39px
      margin-top: auto
      // width: 50%
      @media screen and (max-width: 1024px)
        width: 100%
        gap: 0
        justify-content: space-between
      &_col
        display: flex
        justify-content: center
        align-items: center
        gap: 10px
        @media screen and (max-width: 1024px)
          // width: 50%

        &_title
          font-family: $mainFontMedium
          font-size: 14px
          font-weight: 500
          line-height: normal
          text-align: end
        &_button
          min-height: 40px
          min-width: 40px
          padding: 10px 4px
          display: flex
          justify-content: center
          align-items: center

  &__description
    font-weight: 700
    display: flex
    flex-direction: column
    gap: 0.4rem
    min-height: 44px
  &__content
    min-height: 390px
    background-color: #F4F3F3
    border-radius: 10px
    padding: 30px
    &_head
      &_legend
        display: flex
        flex-direction: column
        border-left: 3px solid $mainOrange
        padding-left: 10px
        gap: 7px
        p
          font-size: 14px
          line-height: 17.5px
          font-weight: 400
          font-style: normal
          font-family: $mainFontLight
        span
          color: $mainOrange
      &_double-bar
        color: red

.graphContent
  &__grid
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 1.5rem
    @media screen and (max-width: 1024px)
      grid-template-columns: 1fr
      gap: 1rem

    &_left
      display: flex
      flex-direction: column
      justify-content: space-between
      &_desc
        padding: 2rem 0 1rem 0
        width: 100%
        font-family: $mainFontMedium
      &_pie
        position: relative
        background-color: $mainGray
        border-radius: 10px
        height: 100%
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        @media screen and (max-width: 1024px)
          padding: 2rem 0 6rem 0

        &_label
          position: absolute
          top: 10%
          left: 10%
          @media screen and (max-width: 1024px)
            top: auto
            bottom: 5%
            left: 5%

        svg
          width: auto

    &_right
      display: flex
      flex-direction: column
      &_desc
        padding: 2rem 0 1rem 0
        font-family: $mainFontMedium
      &_pie
        display: flex
        align-items: center
        position: relative
        background-color: $mainGray
        border-radius: 10px
        padding: 1rem 0
        @media screen and (max-width: 1024px)
          padding: 2rem 0 6rem 0

.pieLabelTitle
  font-family: $mainFontMedium !important
  font-weight: 700 !important
  line-height: 26px !important
  margin: 0.5rem auto !important
  padding-left: 6px
  border-left: 3px solid $mainOrange
  max-width: min-content

.firstPieBox, .secondPieBox
  width: fit-content
  div
    justify-content: start
    width: 200px
    svg
      width: fit-content

.firstPieBox
  div
    height: auto
    svg
      height: 150px

.secondPieBox
  div
    height: auto
    svg
      height: 100px
