.group
    display: flex
    flex-direction: column
    margin-top: 20px
    &__group-header
        display: flex
        justify-content: space-between
        align-items: center
        p
            color: var(--main-color-blue)
            font-family: "Wigrum-Bold"

    &__line
        display: flex
        flex-direction: row
        background-color: var(--main-color-grey)
        padding: 10px
        border-radius: 10px
        margin: 10px 0px
        position: relative
        &:hover
            box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3)

        input
            border: 0
            outline: 0
            border-bottom: 1px solid var(--main-color-blue)
            color: var(--main-color-blue)
            width: 100%
            margin: 10px 0px
            font-size: 16px
            background-color: transparent
            margin: 10px 0px
        select
            all: unset
            width: 100%
            border-bottom: 1px solid var(--main-color-blue)
            color: var(--main-color-blue)
            margin: 10px 0px
        textarea
            border: none
            outline: none
            margin: 10px 0px
            padding: 10px
            color: var(--main-color-blue)
            border-radius: 6px
            width: 100%
        &_left-component
            width: 50%
            margin: 0px 5px
            &_options
                &_num
                    border-radius: 50%
                    background-color: white
                    color: var(--main-color-blue)
                    font-size: 16px
                    font-weight: bold
                    width: 30px
                    height: 30px
                    text-align: center
                    margin-right: 3px
                    display: flex
                    align-items: center
                    justify-content: center
        &_right-component
            width: 50%
            margin: 0px 5px
            &_data
                &_line-amount
                    display: flex
                    align-items: center
                    &_ht
                        font-size: 15px
                        width: 100%
                        span
                            float: right
                            font-family: "Wigrum-Bold"
                            font-size: 16px
                div
                    display: flex
                    align-items: center
                    p
                        width: 100%
                &_unit
                    input
                        text-align: flex-end
                &_unit-enable
                    input
                        border: none
                        border-radius: 6px
                        background-color: white
                        height: 40px

    hr
        border-style: none
        border-top: 3px solid var(--main-color-blue)
        width: 100%
        margin: 5px 0px
