// Conteneur principal pour inclure le titre
.total-amonts-wrapper
    display: flex
    flex-direction: column
    gap: 16px

    &__title
        font-size: 16px
        font-weight: 700
        font-family: $mainFontBold
        text-transform: uppercase
        margin: 20px 0

// Styles existants pour total-amonts restent inchangés
.total-amonts
    display: flex
    align-items: center
    justify-content: center
    padding: 20px
    background-color: #f5f5f5
    border-radius: 8px
    @media screen and (max-width: 450px)
        flex-direction: column
        gap: 10px

    &__item
        display: flex
        flex-direction: column
        align-items: center
        flex: 1
        text-align: center
        gap: 16px
        height: 60px
        @media screen and (max-width: 450px)
            gap: 5px

        &_label
            font-weight: 700
            font-size: 18px
            margin-bottom: 8px
        &_value
            font-size: 16px

    &__separator
        width: 1px
        height: 60px
        background-color: #dcdcdc
        margin: 0 16px
        @media screen and (max-width: 450px)
            width: 80%
            height: 1px
            margin: 16px 0
