.admin-articles
  &__delete-button
    border: none
    border-radius: 5px
    display: flex
    align-items: center
    width: max-content
    padding: 10px 20px
    gap: 10px
    &:hover
      cursor: pointer
      background-color: #d6d6d6
    p
      width: max-content
