.depositInputsTitle
    +sectionTitle
    &_line
        border: none
        height: 3px
        background-color: #666666
        margin-bottom: 2rem

.depositInputs
    display: flex
    justify-content: center
    *
        color: white
        font-weight: 700
    input
        border: none
        border-radius: $mainBorderRadius
        padding: 10px
        color: black
        font-weight: 400
        placeholder
    &__content
        background-color: #FDAB0D
        width: 300px
        border-radius: $mainBorderRadius
        padding: 30px 16px
        display: flex
        flex-direction: column
        gap: 20px
        &_percent, &_amount
            display: flex
            flex-direction: column
            gap: 14px
            &_wrapper
                display: flex
                gap: 15px
                align-items: center
