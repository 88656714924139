.marginInfos
    margin-bottom: 35px
    &__subject
        input
            width: 100%
            height: 40px
    h3
        margin: 0px 0px 30px 0px
    &__infos-block
        display: grid
        grid-template-columns: 1fr 1fr
        gap: 20px
        div
            box-sizing: border-box
            width: 100%
        h4
            margin: 5px 0px
        &_invoice
            display: flex
            width: 50%
            background-color: $mainGray
            border-radius: 10px
            .invoice
                display: flex
                width: 50%
                background-color: $mainGray
                border-radius: 10px
            h4
                margin-left: 1rem
                display: flex
                align-items: center
            &_invoiceSearch
                position: relative
                margin: 0
                margin: 2px 6px 2px 6px
                input
                    user-select: none
                    margin-top: 0.5rem
                    margin-bottom: 0.5rem
                    margin-left: 1rem
                    width: 90%
                    height: 35px
                    border: none
                    padding-left: 1rem
                    border-radius: 5px
                &_suggestions-list
                    list-style-type: none
                    margin: 0
                    margin-left: 1rem
                    padding: 0
                    border: 1px solid #ccc
                    width: calc(100% - 1rem)
                    max-height: 150px
                    position: absolute
                    background-color: white
                    z-index: 1000
                    position: absolute
                    top: 43px
                    & li
                        padding: 8px
                        cursor: pointer

                    & li:hover
                        background-color: #ddd
        &_interlocutor
            div
                display: flex
                align-items: center
                justify-content: space-between
                width: 100%
                margin: 0
            select
                all: unset
                width: 100%
                border-bottom: 1px solid black
                &:hover
                    cursor: pointer
        &_contact
            div
                display: flex
                align-items: center
                justify-content: space-between
                width: 100%
                margin: 0
            p
                width: 100%
                border-bottom: 1px solid black
        &_contact-search
            a
                display: flex
                justify-content: space-between
                text-decoration: none
                padding: 16px 8px
                width: 100%
                background-color: var(--main-color-grey)
                color: var(--main-color-blue)
                border-radius: var(--main-border-radius)
