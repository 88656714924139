.mainHeader
  background-color: #fff
  z-index: 50
  +listPagePadding(30px)
  position: sticky
  top: 0
  display: flex
  flex-direction: column
  &__headerContainer
    +flexBeetweenCenter
    display: flex
    &_title
      text-transform: uppercase
      @media screen and (max-width: 768px)
        font-size: 1.7rem

    &_buttonContainer
      gap: 1rem
      display: flex
      &_deliveryButton, &_createQuoteButton
        +buttonParam($mainGray, $mainBlue, lightgrey)
        p
          color: $mainBlue !important
        svg
          path
            fill: $mainBlue

  &__line
    +mainOrangeLine
    // box-shadow: 0px 5px 9.5px 2px rgba(0, 0, 0, 0.2509803922)
    margin-top: 1rem
