.navigation
    user-select: none
    background-color: $mainBlue
    position: fixed
    z-index: 100000000
    top: 0
    right: 0
    width: 250px
    height: 100vh
    +flexCol
    // justify-content: space-between
    font-family: $mainFont
    @media screen and (min-width: 1024px)
        left: 0
    &__header
        background-color: #142536
        &_figure
            display: flex
            justify-content: center
            &_logo
                width: 80%
                height: auto
                margin: 14px 10px 14px 0
    &__container
        height: 100%
        display: flex
        flex-direction: column
        // justify-content: space-between
        &_links
            flex-grow: 0
            &_ul
                line-height: normal
                &_li
                    // margin-top: 1rem
                    // @media screen and (min-width: 1024px)
                    //     margin-top: 2rem
                    &_link
                        display: flex
                        align-items: center
                        height: 5vh
                        min-height: 30px
                        max-height: 45px
                        text-decoration: none
                        text-transform: uppercase
                        color: $navGray
                        position: relative
                        padding: 0 22px
                        &:hover
                            color: $navGray
                            font-weight: 550
                            span
                                background-color: $white
                        &_span
                            position: absolute
                            height: 100%
                            top: 0
                            left: 0
                            width: 4px
                    .activeNavButton
                        color: $white
                        font-weight: 550
                        span
                            background-color: $mainOrange
        &_buttons
            flex-grow: 0
            padding: $navPadding
            &_link
                text-transform: uppercase
                display: flex
                width: fit-content
                cursor: pointer
                color: $white
                text-decoration: none
                padding: 12px
                border: 2px solid rgba(255, 255, 255, 0.3)
                border-radius: $mainBorderRadius
                font-family: $mainFontLight
                font-size: 14px
                font-weight: 500
                align-items: center
                gap: 1rem
                &:hover
                    color: $navGray
                &_icon
                    path
                        fill: white
            &_button
                display: flex
                align-items: center
                text-transform: uppercase
                border: 2px solid rgba(253, 171, 13, 0.3)
                color: $mainOrange
                background-color: transparent
                padding: 12px
                border-radius: $mainBorderRadius
                cursor: pointer
                margin-top: 8px
                gap: 1rem
                &:hover
                    color: darken($mainOrange, 10%)
                &_icon
                    font-size: 1rem
                    path
                        fill: $mainOrange
        &_footer
            margin-top: auto
            padding: 0 15px 12px 15px
            display: flex
            flex-direction: column
            gap: 12px
            &_p
                color: $navGray
                font-size: 10px
                font-family: $mainFontLight
                font-weight: 300
            &_logo
                width: 70%
                height: auto
            &_buttons
                display: flex
                gap: 8px
                &_button
                    padding: 5px
                    border-radius: $mainBorderRadius
                    border: none
                    background-color: #4c5d6d
                    color: $white
                    cursor: pointer
                    font-size: 10px
                    font-family: $mainFontLight
                    &_icon
                        margin-right: 5px
                        height: 8px
                        path
                            fill: $white
