h3
    font-family: $mainFontMedium
    font-size: 150%

.margin-block
    display: grid
    grid-template-columns: 63% 33%
    gap: 40px
    @media screen and (max-width: 1024px)
        display: block
        gap: 0
        margin-bottom: 2rem
