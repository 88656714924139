.admin-settings
    display: flex
    justify-content: center
    gap: 30px
    margin: 2rem
    @media screen and (max-width: 768px)
        flex-direction: column
        gap: 50px

    &__container
        display: flex
        flex-direction: column
        align-items: center
        gap: 20px
        width: 50%
        height: 100%
        @media screen and (max-width: 768px)
            width: 100%

        &_title
            text-align: center
        &_pdf-viewer
            height: 400px
            width: 100%
