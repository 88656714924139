// listToolBar
.toolbar
    z-index: 50 !important
    +listPagePadding(30px)
    display: flex
    flex-direction: column
    @media screen and (max-width: 1024px)
        padding: 15px 15px 5px 15px
    &__container
        margin-bottom: 1rem
        width: 100%
        gap: 10px
        +flexBeetweenCenter
        @media screen and (max-width: 1024px)
            align-items: flex-start
            margin: 0
        &_title
            text-transform: uppercase
            font-size: 2rem
            &_span
                font-weight: bold
        &_button
            +flexBeetweenCenter
            justify-content: center
            font-size: 1rem
            background-color: $mainOrange
            padding: 15px
            max-height: 55px
            line-height: 55px
            border-radius: $mainBorderRadius
            border: none
            color: $white
            cursor: pointer
            display: flex
            gap: 6px
            white-space: nowrap
            @media screen and (max-width: 1024px)
                padding: 5px
                height: 40px
                // width: 40px
                .hide-text
                    display: none
            @media screen and ( max-width: 650px )
                padding: 5px
                height: 40px
                width: 40px
                .hide-text-650
                    display: none

            &:hover
                background-color: #e68600
            &_icon
                font-size: 1.5rem
                margin: 0 5px
                path
                    fill: $white
        &_header
            display: flex
            align-items: center
            gap: 1vw
            width: 60%
            flex: auto
            @media screen and (max-width: 1024px)
                flex-wrap: wrap

            &_backIcon
                background-color: $mainGray
                padding: 17px
                height: 55px
                border-radius: $mainBorderRadius
                cursor: pointer
                transition: 0.3s
                &:hover
                    transition: 0.3s
                    background-color: rgb(0,0,0,0.1)
                    path
                        fill: $white
                @media screen and (max-width: 1024px)
                    display: none

            &_title
                text-transform: uppercase
                font-size: 2rem
                @media screen and (max-width: 1200px)
                    font-size: 24px
                @media screen and (max-width: 1024px)
                    font-size: 20px
            input
                // min-width: 200px
                width: auto
                max-width: 300px
                height: 55px
                font-size: 26px
                border-radius: 4px
                line-height: 55px
                font-family: $mainFontBold
                font-weight: bold
                padding: 15px 5px
                @media screen and (max-width: 1200px)
                    font-size: 22px
                    height: 40px
                    padding: 0 5px
                    line-height: 40px

                @media screen and (max-width: 1024px)
                    font-size: 20px
                    height: 40px
                    padding: 0 5px
                    line-height: 40px
            &_date
                display: flex
                align-items: center
                &_icon
                    background-color: var(--main-color-grey)
                    padding: 8px 10px
                    border-radius: 10px
                    text-decoration: none
                    color: var(--main-color-blue)
                    font-size: 14px
                    transition: 0.3s
                    margin-right: 8px
                    &:hover
                        background-color: var(--main-color-blue)
                        color: white
                        cursor: pointer
                        svg
                            filter: brightness(0) invert(1)
                &_value
                    font-size: 16px
                    color: var(--main-color-blue)
                    text-decoration: none
                    @media screen and (max-width: 1024px)
                        font-size: 14px

        &_tools
            display: flex
            gap: 5px
            justify-content: flex-end
            align-items: center
            height: 55px
            @media screen and (max-width: 1024px)
                height: 40px
            .mainDeleteButton
                height: 55px
                padding: 17px
                @media screen and (max-width: 1024px)
                    height: 40px
                    padding: 12px

            &_dropdown
                position: relative
                min-width: max-content
                &_dropdown-toggle
                    +flexBeetweenCenter
                    text-decoration: none
                    padding: 12px 14px
                    border-radius: 8px
                    color: var(--main-color-blue)
                    border: 1px solid black
                    display: inline-flex
                    gap: 8px
                    line-height: 55px
                    @media screen and (max-width: 1440px)
                        width: 55px
                    @media screen and (max-width: 1024px)
                        padding: 10px
                        height: 40px
                        width: 40px

                    &:hover
                        cursor: pointer
                        background-color: rgb(0,0,0,0.1)
                    @media screen and (max-width: 1440px)
                        justify-content: center

                &_dropdown-menu
                    display: none
                    position: absolute
                    z-index: 1
                    background-color: white
                    border-radius: 8px
                    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3)
                    padding: 10px
                    right: 0
                    width: 250px
                    min-width: max-content
                    gap: 4px
                    &.open
                        display: flex
                        flex-direction: column
                    div
                        text-decoration: none
                        border-style: none
                        color: var(--main-color-blue)
                        padding: 6px 4px
                        z-index: 2
                        font-size: 16px
                        display: inline-flex
                        width: 100%
                        height: 100%
                        border-radius: 5px
                        gap: 10px
                        &:hover
                            background-color: rgb(0,0,0,0.1)
                        p
                            font-family: $mainFontLight
                div:hover
                    cursor: pointer
    &__line
        +mainOrangeLine
        box-shadow: 0px 5px 9.5px 2px rgba(0, 0, 0, 0.2509803922)
        margin-top: 1rem
