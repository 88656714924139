.marginPrice
    grid-column: 2
    grid-row: 1/2
    top: 157px
    display: flex
    flex-direction: column
    &__bold-block
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        background-color: var(--main-color-blue)
        padding: 1rem
        border-radius: 8px
        margin: 5px 0px
        p
            color: white
            font-size: 18px
            font-family: "Wigrum-Medium"
    &__tva
        display: flex
        flex-direction: column
        padding: 10px
        border-radius: 10px
        gap: 10px
        div
            display: flex
            justify-content: space-between
            margin: 4px 0px
            p
                color: var(--main-color-blue)
                font-size: 16px
                display: flex
