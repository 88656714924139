.admin-tva
    &__new-tva-form
        display: flex
        align-items: center
        gap: 20px
        flex-wrap: wrap
        &_input
            display: flex
            align-items: center
            gap: 10px
        input
            border: 0.5px solid #1f3449
